import { LitElement, html, css } from 'lit'
import Panel from 'panel'
import RoutedView from 'bui/app/views/routed'
import 'bui/presenters/list'
import 'bui/app/views/root-titlebar'
import coll from './models'
import './row'
import './single'
import './edit'
import {isLandscape} from '../map/util'
// import Panel from 'panel'

Panel.register('m-reports', {
    closeBtn: 'arrow',
    width: '600px',
    anchor: !isLandscape() ? 'bottom' : 'right',
    height: !isLandscape() ? '65vh' : '100%',
    width: !isLandscape() ? '100%' : '600px',
    className: 'reports',
})

customElements.define('m-reports', class extends RoutedView {

    static title = 'Reports'
    static icon = 'burst_mode'
    static path = 'reports'

    static styles = [RoutedView.styles, css`

        :host{
            grid-template-rows: 1fr !important;
        }

        .warning {
            text-align: center;
            padding-bottom: .5em;
            padding-top: .5em;
        }

        b-list::part(list) {
            display: grid;
            gap: 1em;
            padding: 1em;
        }

        b-list::part(toolbar) {
            /*border-radius: 12px;
            background-color: var(--theme-bgd);*/
            /*padding-top: calc(.25em + (var(--safe-top)/1.25));*/
        }
        
        /*@media (min-width: 900px) and (min-height: 600px), (min-width: 700px) and (max-height: 700px){
            b-list::part(toolbar) {
                padding-left: 5.5em;
            }

            b-list::part(list) {
                padding-left: 5em;
                grid-template-columns: var(--grid-size);
                justify-content: center;
            }

            .warning {
                padding-left: 5em;
            }
        }*/

        
    `]

    constructor(){
        super()
        this.coll = coll

        window.addEventListener('map:clicked', this.closeIfOnTop.bind(this))
        window.addEventListener('map:area-changed', this.onMapAreaChange.bind(this))
    }

    closeIfOnTop(){
        if( this.panel.onTop )
            this.close()
    }

    render(){return html`
        <b-list
            key="reports"
            row="m-reports-row"
            .listOptions=${{
                fetch: 'more', 
                //grid: '640px',
                perPage: 10,
                fetchData: 'with[files]'
            }}
            .filters=${filters}
            .coll=${this.coll}
            @edit=${this.editRow}
            @view=${this.viewRow}
            @filter-change=${this.onFilterChange}
        >

            <b-root-titlebar></b-root-titlebar>

            <!--<b-text sm muted slot="footer" class="warning">
                This feature not yet open to the public
            </b-text>-->
            <b-flex slot="header" ?hidden=${!this.mapAreaChanged||this.list.filters.value('location')!='map'}
                style="margin: 1em 1em 0 1em;">
                <b-text>Map area changed</b-text>
                <b-btn @click=${this.refresh} clear color="theme">Refresh</b-btn>
            </b-flex>
            
            <b-btn slot="toolbar:before" color="theme" clear lg icon="add_box" @click=${this.addNew}></b-btn>

            ${device.isSmall?html`
                <b-panel-fullscreen-btn .panel=${this.panel} slot="toolbar:after" text></b-panel-fullscreen-btn>    
            `:''}
        </b-list>
    `}

    editRow(e){
        let {model} = e.detail || e
        if( model && model.isMine )
            goTo('report/edit/'+model.id)
        else if( model )
            goTo('report/'+model.id)
    }

    viewRow(e){
        let {model} = e.detail || e
        if( model )
            goTo('report/'+model.id)
    }

    addNew(e){
        if( user.id != 3 )
            throw new UIAlertError('Not yet open to the public', {target: e.currentTarget})

        goTo('report/edit/new')
    }

    // NOTE: these next few methods are a bit messy
    onMapAreaChange(e){
        let {bounds} = e.detail
        // clearTimeout(this._changeAreaTimeout)

        if( this.list.filters.value('location') == 'map' ){
        // this._changeAreaTimeout = setTimeout(()=>{
            // let area = [bounds._ne.lng, bounds._sw.lng, bounds._ne.lat, bounds._sw.lat]
            let area = mapView.visibleBounds()
            this.mapAreaChanged = area.join('|')
            this.requestUpdate()
        }

        if( this.panel.anchor == 'right' )
            this.refresh()
        // },300)
    }

    onFilterChange(e){
        let {changes} = e.detail

        console.log(changes);
        if( changes.location !== undefined && changes.location != 'map' ){
            this.requestUpdate()
        }if( changes.location == 'map' && window.mapView){
            console.log('set bounds?');
            // let bounds = mapView.map.getBounds()
            // let area = [bounds._ne.lng, bounds._sw.lng, bounds._ne.lat, bounds._sw.lat]
            let area = mapView.visibleBounds()
            this.mapAreaChanged = area.join('|')
            setTimeout(()=>{
                this.refresh()
            }, 200) // hacky
        }
    }

    refresh(){
        // this.list.refresh()
        this.list.filters.update({map_area: this.mapAreaChanged})
        this.mapAreaChanged = false
        this.requestUpdate()
    }

})

export default customElements.get('m-reports')

const filters = {
    search: {hideIcon: true},
    options: {
        history: false,
        presets: false,
    },
    show: {
        db: true,
        values: [
            {label: 'All Kinds', val: ''},
            '-',
            {label: 'Videos', val: 'videos'},
        ]
    },
    location: {
        db: true,
        values: [
            {label: 'Anywhere', val: ''},
            '-',
            {label: 'Map Area', val: 'map'},
        ]
    },
    map_area: {
        db: true,
        show: false,
        view: 'input'
    }
}