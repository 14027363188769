
import './style/index.less';

import router from 'bui/app/router'
router.config({
    root: '/',
    urlPattern: {
        segmentValueCharset: 'a-zA-Z0-9-_~ %.'
    }
})

import 'bui/helpers/app-height-fix'
import 'bui/helpers/fetch'
import 'bui/helpers/lit'
import 'bui/helpers/backbone/attr-types'
import 'bui/helpers/backbone/singleton'
import 'bui/helpers/backbone/promises'
import 'bui/helpers/backbone/relations'
import 'bui/app/error-handler'
import 'bui/app/background-resume'
import 'bui/app/network-monitor'
import './icons'
// import StandardIcons from 'bui/elements/icon/legacy/standard'
import dayjs from 'dayjs'
import Backbone from 'backbone'
import 'bui/app/tabbar'

Backbone.registerModelAttrType('date', val=>{
    return dayjs(val)
})

window.goTo = function(path, props){
    router.goTo(path, props)
}


import device from 'bui/util/device'
import colorScheme from 'bui/util/color-scheme'
import AppInstaller from 'bui/app/installer'
import user from './user'

window.detailView = undefined
window.mapView = undefined

import './version'
import './app'
import './sw'

import Dialog from 'dialog'

window.addEventListener('DOMContentLoaded', async e=>{

    window.user = user // TEMP
    await user.fetchSync()
    user.applyTheme()

    device.applyClasses()
    // colorScheme.apply({theme: 'light', watch: false})

    document.body.appendChild(document.createElement('s-app'))

    window.addEventListener('background-resume', e=>{
        user.applyTheme()
    })

    // TEMP
    if( location.host == 'skireport.app' ){
        if( device.isInstalled )
            Dialog.alert(`This is the old url.<br>You should install the new version:<br><b>mountainreport.app</b>`).notif({autoClose: false})
        else
            location = 'https://mountainreport.app'+location.pathname
    }else{

        AppInstaller.promptInstall({delay: 2000, once: true})
        
    }
})
