import { LitElement, html, css } from 'lit'
import App from 'bui/app/tabbar'
import AppInstaller from 'bui/app/installer'
import {mediaQuery} from 'bui/util/mediaQueries'
import './about'
// import './stoke'
import './map/index.js'
import './detail'
import './account'
import './reports'
import 'bui/elements/flex'
import colorScheme from 'bui/util/color-scheme'
import user from './user'

customElements.define('s-app', class extends App{

    static get styles(){return [super.styles, css`

        :host {
            --logo-color: currentColor;
        }

        @media (min-width: 700px){
            b-tabs-router::part(tab-bar) {
                position: absolute;
                left: 0;
                z-index: 100;
                background: white;
                margin: 0.5em;
                border-radius: 3em;
                --btn-radius: 3em;
                box-shadow: var(--theme-shadow-1);
                --padding: .3em 0;
            }

            b-tabs-router::part(content) {
                grid-column: 2;
            }
        }
        
        footer {
            text-align: center;
            align-items: center;
            padding-bottom: 0.5em;
            justify-content: flex-end;
            flex-direction: column;
            display: none;
        }

        ${mediaQuery('b-app-landscape', css`
            footer {
                display: flex;
            }
        `)}
    `]}

    constructor(...args){
        super(...args)
        AppInstaller.canInstallPromise.then(e=>this.update())

        this.addEventListener('active-changed', e=>{
            if( e.detail.tabView.id == 'map' )
                colorScheme.setMetaTheme('#adb39c')
            else{
                window.detailView?.close()
                colorScheme.setMetaTheme()
            }
        })
    }

    get views(){return html`
        s-map
        s-about
        s-account
        
        <!-- <b-btn slot="menu:after" color="theme" clear @click=${this.openAccount} icon="account_circle" stacked>
            Account
        </b-btn> -->

        <b-app-tab-bar-btn slot="menu:after:map" label="Reports" icon="burst_mode" @click=${this.openReports}></b-app-tab-bar-btn>


        ${AppInstaller.canInstall?html`
        <footer col bottom slot="menu:after">
            <b-btn color="theme" clear @click=${AppInstaller.install}>
                Install
            </b-btn>
        </footer>
        
        `:''}
    `}

    openReports(){
        goTo('reports')
    }

    openAccount(){
        goTo('account')
    }

    get shouldShowSearch(){ return false }

})

export default customElements.get('s-app')