import { LitElement, html, css } from 'lit'
import Previewer from 'bui/presenters/previewer'
import device from 'bui/util/device'

customElements.define('m-report-google-photos', class extends LitElement{

    static properties = {
        limit: {type: Number},
        metadata: {type: Boolean}
    }

    static listeners = {
        model: {'change:google_photos': 'requestUpdate'}
    }

    static styles = css`
        :host {
            display: contents
        }

        img {
            max-width: 100%;
            height: 100%;
        }

        :host([grid]) img,
        :host([grid]) .img {
            line-height: 0;
            aspect-ratio: 1/1;
            object-fit: cover;
        }

        [colspan] {
            grid-column: 1/-1
        }
    `

    img(str){
        return this.fullsize ? str : str.replace('=w1920-h1080', '')
    }

    get data(){
        return this.model.get('google_photos')
    }

    render(){return html`

        ${this.metadata&&this.data?html`
        <b-flex colspan>
            <b-text semibold link @click=${this.openAlbum}>${this.data.title}</b-text>
            <b-text muted sm>
                fetched <b-ts .date=${this.data.timestamp*1000}></b-ts>
            </b-text>
        </b-flex>
        `:''}

        ${this.data?.mediaItems?.map((d,i)=>!this.limit||i<this.limit?html`
            <div class="img"><img loading="lazy" src="${this.img(d.url)}" part="img" @click=${this.view} .model=${d}></div>
        `:'')}

        ${this.limit&&this.data?.mediaItems?.length>this.limit?html`
            <b-text dim italic sm block align="center" colspan>+ ${this.data.mediaItems.length-this.limit} more</b-text>
        `:''}
    `}

    openAlbum(){
        window.open(this.data.url)
    }

    view(e){
        let {model} = e
        let data = new Map()
        let url = model.url

        // data.previewURL = url.replace('=w1920-h1080', '')

        if( !device.isSmall ){
            // really slow
            // url = url.replace('w1920-h1080', 'w3192-h2394')
        }

        data.displayURL = url
        data.set('ext', 'jpg')
        Previewer.open(data)
    }

})

export default customElements.get('m-report-google-photos')