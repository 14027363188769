import { LitElement, html, css } from 'lit'
import {unsafeHTML} from 'lit/directives/unsafe-html.js'
import device from 'bui/util/device'

customElements.define('m-detail-feature-video-feed', class extends LitElement{

    static properties = {
        url: {type: String}
    }

    static styles = css`
        :host {
            display: block;
            position:relative;
        }

        video {
            width: 100%;
            background-color: var(--theme-bgd-accent);
        }

        :host > div > div {
            display: none;
        }

        button {
            display: none;
        }

        .vjs-playing ~ [fab],
        .vjs-playing ~ b-empty-state {
            display: none;
        }
    `

    firstUpdated(){
        super.firstUpdated()
        this.setup()
    }

    connectedCallback(){
        super.connectedCallback()
        if( this.hasSetupBefore )
            this.setup()
    }

    disconnectedCallback(){
        super.disconnectedCallback()
        this.destroy()
    }

    setup(){
        console.log('setup');
        this.hasSetupBefore = true

        setTimeout(()=>{
        // delayed (dynamic) import
        import('video.js')
        .then(({ default: videojs }) => {

            let video = this.$$('video')
            if( video )
            this.player = videojs(video, {
                controls: false,
                // autoplay: device.isMobile ? false : true
                autoplay: true
            })

        }).catch(err=>{

        })

        })
    }

    destroy(){
        this.player?.pause()
        this.player?.dispose()
        this.player = null
        this.url = null
    }

    render(){return html`
        ${unsafeHTML(/*html*/`
        <video preload="none" crossorigin="anonymous" playsinline>
            <source src="${this.url}" type="application/x-mpegURL">
        </video>
        `)}
        <b-btn fab icon="play_arrow" @_click=${this.play}></b-btn>
        <b-empty-state><slot name="placeholder"></slot></b-empty-state>
    `}

    clickMenu(){
        this.togglePlay()
    }

    togglePlay(){
        this.player?.paused() ? this.play() : this.pause()
    }

    play(){ this.player?.play() }
    pause(){ this.player?.pause() }

})

export default customElements.get('m-detail-feature-video-feed')