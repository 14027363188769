import CollMap from 'bui/util/collmap'
import Emmiter from 'component-emitter'
import store from 'bui/util/store'

class Favorites extends CollMap {

    toGeoJSON(){
        let features = this.map(data=>{
            return {
                "type": "Feature",
                "geometry": {
                    "type": "Point",
                    "coordinates": [parseFloat(data.lng), parseFloat(data.lat)]
                },
                "properties": data
            }
        })

        return {
            type: "FeatureCollection",
            crs: { 
                type: "name",
                properties: { name: "favorites" }
            },
            features: features
        }
    }

    _storeUpdate(...args){
        super._storeUpdate(...args)
        this.updateMap()
    }

    set mapView(map){
        this._mapView = map
        this.updateMap()
    }

    get mapView(){ return this._mapView }

    updateMap(){
        if( !this.mapView ) return

        let source = this.mapView.getSource('favorites')
        
        if( source )
            return source.setData(this.toGeoJSON())
        
        this.mapView.addSource('favorites', {
            type: 'geojson',
            data: this.toGeoJSON()
        });
        
        this.mapView.addLayer({
            'id': 'favorites-layer',
            'type': 'circle',
            'source': 'favorites',
            'paint': {
                'circle-radius': 8,
                'circle-stroke-width': 1,
                'circle-color': 'orange',
                'circle-stroke-color': 'white'
            }
        });
    }

}
Emmiter(Favorites)

export default new Favorites(null, {store: store.create('favorites', {})})