import { LitElement, html, css } from 'lit'
import 'bui/helpers/lit/contextmenu'
import 'bui/elements/embed'
import './video-feed'
import './usgs'

customElements.define('m-detail-feature', class extends LitElement{

    static styles = css`
        :host {
            display: block;
            position:relative;
        }

        img {
            width: 100%;
            max-width: 100%;
        }

        :host > b-table {
            margin: var(--gutter);
            margin-top: 0;
        }
    `

    disconnectedCallback(){ 
        super.disconnectedCallback()
        this.model = null
    }

    updated(){
        this.setAttribute('type', this.model?.get('type'))
    }

    render(){return html`
        
        ${this.model?.get('type')=='snotel'?html`
        <s-snotel .model=${this.model}></s-snotel>
        `:''}

        ${this.model?.get('type')=='usgs'?html`
        <m-feature-detail-usgs .model=${this.model}></m-feature-detail-usgs>
        `:''}

        ${this.model?.get('type')=='cam'&&this.model.isImg?html`
            <img src=${this.model.url()+'/cam'}>
        `:''}

        ${this.model?.get('type')=='cam'&&this.model.isFeed?html`
            <m-detail-feature-video-feed url=${this.model.feedUrl+'?'+new Date().getTime()}>
                <span slot="placeholder">${this.model.get('name')}</span>
            </m-detail-feature-video-feed>
        `:''}

        ${this.model?.get('type')=='cam'&&this.model.get('props')?.embed?html`
            <b-embed url=${this.model.get('props')?.embed}></b-embed>
        `:''}

        ${this.model?.get('type')=='air'?html`

         <b-table rounded>
            <b-table-row slot="header">
                <b-text w="2fr" xbold md>Air Quality</b-text>
                <b-text w="1fr">AQI</b-text>
                <b-text w="1fr">Param</b-text>

            </b-table-row>

            <b-table-row>
                <b-text semibold clip style="color: ${this.model.airQualityColor}">
                    ${this.model.aqiInfo()?.level||'Unknown Air Quality'}
                </b-text>

                <b-text>${this.model.get('props')?.AQI}</b-text>

                <b-text dim>${this.model.get('props')?.parameter}</b-text>

            </b-table-row>

            <b-table-row>
                <b-grid cols=1 colspan gap=" ">

                    <b-text italic heading sm>
                        ${this.model.aqiInfo()?.detail||''}
                    </b-text>

                    <b-text dim sm>as of <b-ts .date=${this.model.airQualityTS}></b-ts></b-text>

                </b-grid>
            </b-table-row>
        </b-table>
        `:''}
    `}

})

export default customElements.get('m-detail-feature')