import { LitElement, html, css } from 'lit'
import RoutedView from 'bui/app/views/routed'
import {Panel, Menu, Dialog} from 'bui'
import device from 'bui/util/device'
import coll from './models'
import 'bui/app/views/edited-model-btns'
import 'bui/components/filemanager'
import 'bui/elements/ts'
import 'bui/elements/spinner'
import dayjs from 'dayjs'
import FileRow from 'bui/components/filemanager/row'
import InlineEdit from 'bui/presenters/form/controls/inline-edit'
import {bindLongpress} from 'bui/util/touch-events'
import vibrate from 'bui/util/vibrate'
import './google-photos'

Panel.register('m-report-edit', {
    closeBtn: 'arrow', 
    width: '600px', 
    anchor: device.isSmall?'bottom':'right',
    className: 'reports',
})

customElements.define('m-report-edit', class extends RoutedView {

    static listeners = {
        model: {
            'change:title': 'updateTitle',
            'change:lng change:lat': 'requestUpdate',
            'change:published change:video': 'requestUpdate',
        },
        files: {
            'add remove': 'onFilesChange',
        }
    }

    static title = 'Report'
    // static icon = ''
    static path = 'report/edit/:id'

    static styles = [RoutedView.styles, css`

        :host {
            grid-template-columns: 1fr;
        }

        main {
            padding-top: .5em !important;
        }

        form-control {
            --disabledColor: var(--theme-text);
        }

        [key="title"] {
            font-size: var(--font-size-lg);
            font-weight: 900;
        }
    `]

    constructor(){
        super(...arguments)
        this.coll = coll
        this.onMapLongPress = this.onMapLongPress.bind(this)
    }

    updateTitle(){
        if( this.panel )
            this.panel.title = this.model ? this.model.get('title') : this.constructor.title
    }

    onModelChange(){
        this.updateTitle()
    }

    modelRequired = true

    onOpen(){
        window.addEventListener('map:longpress', this.onMapLongPress)
    }

    onClose(){
        super.onClose()
        window.removeEventListener('map:longpress', this.onMapLongPress)
        this.model = null
    }

    async willOpen(state){
        console.log('will open');

        if( state.params.id!='new' ) return true

        let attrs = {}
        
        let [model] = await this.coll.createSync(attrs, {wait: true})

        if( model )
            goTo(this.makePath({id: model.id}))

        return false
    }

    finishLoading(model){
        if( !model.isMine ){
            throw new UISilentError()
            // setTimeout(()=>{ this.close() }, 100)
            // return false
        }
        
        return super.finishLoading(...arguments)
    }


    render(){return html`
        <b-panel-toolbar notitle overlay>
            <!--<b-edited-model-btns .model=${this.model}></b-edited-model-btns>-->
            <b-btn icon="more_vert" clear slot="right" @click=${this.showOptions}></b-btn>
            
        </b-panel-toolbar>
        <main><form-handler .model=${this.model} autosave="patch" ?disabled=${this.model.get('published')}>
        <b-grid cols=1 gap=2>

            <b-grid cols=1 gap="0">

                <b-text align="center">
                <form-control key="date" material="hover" _label="Date">
                    <text-field type="date" placeholder="Date"></text-field>
                </form-control>
                </b-text>

                
                <form-control key="title" material="hover" _label="Title">
                    <text-field placeholder="Title"></text-field>
                </form-control>

                <form-control key="body" material="" _label="Description">
                    <text-field multi html placeholder="Description"></text-field>
                </form-control>

                <b-text class="gps" body dim style="margin-top: .5em">
                    <b-text semibold>Location: </b-text>
                    ${this.get('lng')||'-'}, ${this.get('lat')||'-'}
                    <b-btn clear color="theme" @click=${this.changeGPS}
                        ?disabled=${this.model.get('published')}>change</b-btn>
                </b-text>

            </b-grid>

            <b-grid cols=1 gap=".5">
                <b-btn block pill color="theme-gradient" ?hidden=${this.model.get('published')} @click=${this.publish}>Publish</b-btn>
                <b-btn block pill color="black" ?hidden=${!this.model.get('published')} @click=${this.unpublish}>Unpublish</b-btn>
                <b-text sm muted italic align="center">Only published reports are visible to other users</b-text>
            </b-grid>

            <!--<check-box key="share_location" type="switch" ?hidden=${!this.model.hasGPS}>
                Share Location
                <b-text slot="help">GPS coordinates found. Enable this to let people see the location.</b-text>
            </check-box>-->

            <b-text-divider xbold sm ucase>
                Photos
                <b-btn clear color="theme" slot="right" @click=${this.upload} ?hidden=${this.model.get('published')}>Upload</b-btn>
            </b-text-divider>

            <b-file-manager .model=${this.model} 
                row="m-report-file-row" 
                accept=".jpg,.png,.dng,.DNG,.jpeg,.JPEG"
                ?disabled=${this.model.get('published')}
                @will-take-action=${this.onFileAction}
            ></b-file-manager>

            <b-text-divider xbold sm ucase>YouTube Video</b-text-divider>

            <form-control key="video" material="filled" _label="YouTube URL">
                <text-field placeholder="https://youtube.com/...."></text-field>
            </form-control>

            ${this.get('video')?html`
                <b-embed url=${this.get('video')} style="margin-top: -1em"></b-embed>
            `:''}

            <b-text-divider xbold sm ucase>Google Photos
                <b-btn slot="right" color="theme" clear 
                    ?disabled=${this.model.get('published')}
                    @click=${this.addGooglePhotos}>${this.get('google_photos')?'Change':'Add'}</b-btn>

                <b-btn slot="right" color="theme" clear 
                    ?disabled=${this.model.get('published')}
                    @click=${this.refreshGooglePhotos} ?hidden=${!this.get('google_photos')}>Refresh</b-btn>
            </b-text-divider>

            <b-grid cols=3>
            <m-report-google-photos .model=${this.model} metadata></m-report-google-photos>
            </b-grid>
            
        
        </b-grid>
        </form-handler>
        </main>
    `}

    async refreshGooglePhotos(e){
        let btn = e.currentTarget
        btn.spin = true
        try{
            let url = this.model.get('google_photos')?.url
            await this.model.saveSync({google_photos: url}, {patch: true, wait: true})
        }finally{
            btn.spin = false
        }
    }

    async addGooglePhotos(e){
        let btn = e.currentTarget
        let selected = await Dialog.prompt({
            val: this.model.get('google_photos')?.url,
            placeholder: 'https://photos.app.goo.gl/....',
            btns: ['cancel', 'save']
        }).popOver(btn, {align: 'top-end', width: '400px', adjustForMobile: true})
        if( selected === false ) return

        btn.spin = true
        try{
            await this.model.saveSync({google_photos: selected}, {patch: true, wait: true})
        }finally{
            btn.spin = false
        }
    }

    onMapLongPress(e){
        let {action} = e.detail
        
        // disable default map long press action (weather)
        action.allowed = false

        if( this.settingGPS ){
            this.panel.height = '100%'
            this.settingGPS.close()
            this.settingGPS = null
            let {lng, lat} = action.lngLat
            this._saveGPS(lng, lat, 'user')
        }
    }

    _saveGPS(lng, lat, gps_from='user'){
        this.model.saveSync({lng, lat, gps_from}, {patch: true})
        vibrate([350]) // untested
        // throw new UIAlertError(`Location updated`, {target: this.$$('.gps')})

        let map = window.mapView?.map
        if( map ){

            let key = 'set-gps'
            map.addSource(key, {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': [{
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [lng, lat]
                        }
                    }]
                }
            })

             map.addLayer({
                'id': key,
                'type': 'circle',
                'source': key,
                'paint': {
                    'circle-radius': 14,
                    'circle-color': '#FF5252'
                }
            });

            setTimeout(()=>{
                map.removeLayer(key)
                map.removeSource(key)
            },400)
        }
    }

    async changeGPS(e){

        let btn = e.currentTarget
        let filesWithGPS = this.model.get('files').filter(m=>m.hasGPS)
        let menu = [
                {label: 'Manually Set'},
                {label: 'None', val: 'none'},
            ]

        if( filesWithGPS.length ){
            menu = menu.concat([{divider: 'Use GPS from image'}]).concat(filesWithGPS.map(m=>{
                return {label: m.get('orig_filename'), val: m}
            }))
        }

        let selected = await new Menu(menu).popOver(btn)

        if( !selected ) return
        if( selected.val ){ 
            let [lng, lat] = selected.val == 'none' ? ['', ''] : selected.val.lngLat()
            this._saveGPS(lng, lat, lng?'img':'user')
            return
        }

        this.settingGPS = new Dialog({
            msg: 'Long press on the map to set location',
            color: 'inverse',
            btns: [
                {label: 'Cancel', color: 'theme'}
            ],
            toast: true,
        })

        // mobile        
        if( this.panel.anchor == 'bottom' ){
            this.panel.height = '10vh'
        }

        this.settingGPS.notif({
            autoClose: false,
            closeOnClick: false,
            anchor: this.panel.anchor == 'bottom' ? 'bottom': 'top'
        }).then(btn=>{
            this.panel.height = '100%'
            this.settingGPS = null
        })
    }

    onFilesChange(){

        let fileWithDate = this.model.get('files').find(m=>m.date)
        let attrs = {}

        if( !this.model.get('date') && fileWithDate ){
            attrs['date'] = dayjs(fileWithDate.date).format('YYYY-MM-DD')
        }

        let fileWithGPS = this.model.get('files').find(m=>m.hasGPS)
        if( fileWithGPS && (!this.model.get('lng') || this.model.get('gps_from')=='img') ){
            let [lng, lat] = fileWithGPS.lngLat() || []
            attrs.lng = lng
            attrs.lat = lat
            attrs.gps_from = 'img'
        }

        console.log(attrs);

        if( Object.keys(attrs).length )
            if( this.formHandler.autoSave )
                this.model.save(attrs, {patch: true})
            else
                this.model.editAttr(attrs)


        this.requestUpdate()
    }

    onFileAction(e){
        let {action} = e.detail
        if( this.model.get('published') ){
            action.allowed = false
            if( action.clickTarget)
            throw new UIAlertError('Unpublish first', {target: action.clickTarget})
        }
    }

    upload(){
        this.$$('b-file-manager', true).selectFile()
    }

    publish(e, publish=true){
        let btn = e.clickTarget

        if( this.model.isEdited() )
            throw new UIAlertError('Unsaved changes', {target: btn})

        if( !this.model.get('title') )
            throw new UIAlertError('A title is required', {target: btn})
        
        btn.spin = true
        this.model.saveSync({published: publish?new Date():null}, {wait: true, patch: true}).finally(e=>{
            btn.spin = false
        })
    }

    unpublish(e){
        return this.publish(e, false)
    }

    showOptions(e){
        e.clickTarget
        new Menu([
            this.model.get('published')?{label: 'Unpublish', val: 'unpublish', fn: 'unpublish'}:'',
            {label: 'Delete', fn: 'destroy'},
            {text: html`Created <b-ts .date=${this.model.get('ts_created')}></b-ts>`, bgd: false}
        ], {handler: [this, e]}).popOver(e.currentTarget)
    }

    async destroy(e){
        if( await Dialog.confirmDelete().popOver(e) ){
            this.model.destroySync()
            this.close()
        }
    }

})

export default customElements.get('m-report-edit')

// import { LitElement, html, css } from 'lit'

customElements.define('m-report-file-row', class extends FileRow{

    static get listeners(){return {
        model: {'change': 'requestUpdate'}
    }}

    static styles = [FileRow.styles, css`
        
        :host {
            --img-max-height: 40vh;
        }

        b-paper {
            border-radius: 1em;
            overflow: hidden;
        }

        [part="preview"] {
            padding: 0em;
            padding-bottom: 0;
            background-color: var(--theme-bgd);
        }

        [part="preview"] img {
            border-radius: 0;
        }

        [part="size"] {
            display: none;
        }

        .delete {
            position: absolute;
            z-index: 90;
            top: -.5em;
            right: -.5em;
        }
    `]

    constructor(){
        super()
        this.nodrag = true
    }

    // firstUpdated(){
    //     bindLongpress(this.$$('.img'), {touchOnly: false})
    // }

    render(){return html`
        <b-btn class="delete" icon="close" pill color="red" @click=${this.destroy}></b-btn>
        ${super.render()}
    `}

    renderContent(){return html`

        <b-flex gap-row=0 gap="1" stretch>

            <!--<b-text header sm semibold link @click=${this.rename} class="filename">${this.model.origFilenameLabel}</b-text>-->

            <b-text sm italic header link @click=${this.editDescription} class="caption" grow>
                ${this.model.get('description')||html`<b-text muted>No caption</b-text>`}
            </b-text>

            <b-text sm>
            <check-box key="share_location" type="switch" ?checked=${this.model.shareGPS} @change=${this.saveShareGPS}
                ?hidden=${!this.model.hasGPS}>
                Share Location
            </check-box>
            </b-text>


        </b-flex>
    
    `}

    showMenu(e){
        if( this.willTakeAction('show-menu').notAllowed ) return

        e.stopPropagation()
        e.preventDefault()
        new Menu([{label: 'Delete', fn: 'destroy'}],  {handler: [this, e]}).popOver(e)
    }

    saveShareGPS(e){
        if( this.willTakeAction('edit-share-gps', {clickTarget: e.currentTarget}).notAllowed ){
            e.currentTarget.checked = !e.currentTarget.checked
            return false
        }

        this.model.saveTrait({shareGPS: e.currentTarget.checked})
    }

    // async rename(){

    //     if( this.willTakeAction('rename').notAllowed ) return
        
    //     let target = this.$$('.filename')
    //     let val = await InlineEdit.edit({
    //         target,
    //         block: true,
    //         value: this.model.origFilenameLabel,
    //         // suffix: '.'+this.model.get('ext')
    //     })

    //     if( val )
    //         this.model.saveSync({orig_filename: val}, {patch: true})
    // }

    async editDescription(e){

        if( this.willTakeAction('edit-description', {clickTarget: e.currentTarget}).notAllowed ) return
        
        let target = this.$$('.caption')
        let val = await InlineEdit.edit({
            target,
            block: true,
            placeholder: 'No caption',
            value: this.model.get('description'),
        })

        if( val !== false )
            this.model.saveSync({description: val}, {patch: true})
    }

})

// export default customElements.get('m-report-file-row')