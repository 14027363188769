import { LitElement, html, css } from 'lit'
import { fixOutliers } from 'bui/util/math'
import colorScheme from 'bui/util/color-scheme'
import {
  Chart,
  ArcElement,
  LineElement,
//   BarElement,
  PointElement,
//   BarController,
  BubbleController,
//   DoughnutController,
  LineController,
//   PieController,
//   PolarAreaController,
//   RadarController,
//   ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
//   BarElement,
  PointElement,
//   BarController,
//   BubbleController,
//   DoughnutController,
  LineController,
//   PieController,
//   PolarAreaController,
//   RadarController,
//   ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

export {fixOutliers}

customElements.define('s-chart', class extends LitElement{

    static get properties(){ return {
        type: {type: String}
    }}

    static get styles(){return css`
        :host {
            display: grid;
            grid-template-rows: auto 1fr auto;
            height: 300px;
            width: 400px;
        }

        header, footer {
            position: sticky;
            left: 0;
            width: 100%;
            max-width: calc(100vw - (var(--gutter) * 2));
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .canvas-wrap {
            position: relative;
        }
        .canvas-wrap,
        canvas {
            grid-row: 2;
            grid-column: 1;
            height: 100%;
            width: 100%;

        }

        :host([gutter]) .canvas-wrap,
        :host([gutter]) canvas {
            margin-left: var(--gutter-negative);
        }
    `}

    constructor(){
        super()
        this.type = 'line'
    }

    set data(data){
        this._data = data
        setTimeout(()=>{
            this.renderChart()
        })
    }

    firstUpdated(){
        this.setAttribute('chart', '')
    }

    get data(){ return this._data }

    render(){return html`
        <header part="header"><slot name="header">

            <div>
                <b-text lg bold>${this.title}</b-text>
                <div><slot name="header:left"></slot></div>
            </div>

            <div>
                <slot name="header:right"></slot>
                <s-chart-legend .chart=${this.chart}></s-chart-legend>
            </div>

        </slot></header>

        <canvas></canvas>
        <div class="canvas-wrap"></div>

        <footer part="footer"><slot name="footer">
            <div><slot name="footer:left"></slot></div>
            <div><slot name="footer:right"></slot></div>
        </slot></footer>
    `}

    get canvas(){ return this.$$('canvas', '__canvas') }

    renderChart(){

        if( !this.data || !this.canvas ) return

        let options = Object.assign({
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {display: false}
            }
        }, this.options)

        let data = this.chartData

        if( options.scales.y.suggestedMax == undefined ){
            let maxVal = Math.max(...data.datasets.flatMap(d=>d.data))
            options.scales.y.suggestedMax = maxVal + 10
        }

        if( options.scales.y.suggestedMin == undefined ){
            let minVal = Math.min(...data.datasets.flatMap(d=>d.data).filter(d=>d))
            options.scales.y.suggestedMin = minVal - 10
        }

        if( this.chart ){
            this.chart.data = data
            this.chart.update()
        }else{
            
            this.chart = new Chart(this.canvas, {
                type: this.type,
                data: data,
                options,
                plugins: this.plugins || []
            })

        }

        this.update()
    }

})

export default customElements.get('s-chart')

customElements.define('s-chart-legend', class extends LitElement{

    static get properties(){return {
        chart: {type: Object}
    }}

    static get styles(){return css`
        :host {
            display: block;
            position:relative;
            display: flex;
            flex-wrap: wrap;
            gap: 1em;
            row-gap: .5em;
            justify-content: flex-end;
        }

        .point {
            min-width: min-content;
            display: flex;
            gap: .25em;
        }

        .point > div {
            display: inline-block;
            width: .5em;
            height: 1em;
            border-radius: 1em;
            background-color: var(--color);
        }  
    `}

    render(){return html`
        ${this.chart?this.chart.data.datasets.map(d=>html`
            <div class="point" style="--color:${d.borderColor}">
                <div></div>
                ${d.label}
            </div>
        `):''}
    `}

})

export const Ticks = {
    
    byHour(value, index, values) {

        if( index % 2 ) return

        value = this.chart.data.labels[index]

        if( index == 0 || value.hour() == 7 || value.hour() == 8 )
            return value.format('dd ha')

        return value.format('ha')
    }
}

export const Plugins = {

    // originally from: https://stackoverflow.com/a/45652892/484780
    addPointLabels({every=6, prefix='', suffix='', format=null}={}){

        return {afterDatasetsDraw: function(chart){

        var ctx = chart.ctx;
        chart.data.datasets.forEach((dataset, datasetIndex)=>{

            var datasetMeta = chart.getDatasetMeta(datasetIndex);
            if (datasetMeta.hidden) return;

            datasetMeta.data.forEach((point, index)=>{
                
                let value = dataset.data[index]

                if( value == null ) return

                value = prefix+value+suffix

                if( index % every ) return
                
                let x = point.getCenterPoint().x
                let y = point.getCenterPoint().y
                let radius = point.options.radius
                let fontSize = 14
                let fontFamily = 'Verdana'
                let fontColor = colorScheme.isWhatTheme() == 'dark' ? 'white' : 'black'
                let fontStyle = 'normal';

                if( datasetIndex % 2 )
                    y = y + radius + fontSize
                else
                    y = y - radius - fontSize

                if( format )
                    value = format(value, index, datasetIndex, {ctx, x, y})

                ctx.save();
                ctx.textBaseline = 'middle';
                ctx.textAlign = 'center';
                ctx.font = fontStyle + ' ' + fontSize + 'px' + ' ' + fontFamily;
                ctx.fillStyle = fontColor;
                ctx.fillText(value, x, y);
                ctx.restore();
            })
        })

        }}
    }
}