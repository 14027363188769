import { LitElement, html, css } from 'lit'
import Notif from 'notif'
import Btn from 'bui/elements/btn'

customElements.define('sr-location', class extends Btn{

    // static get styles(){return css`
    //     :host {
    //         display: block;
    //         position:relative;
    //     }
    // `}

    constructor(){
        super()
        this.icon = 'direction'

        this.addEventListener('click', this.locateMe)
    }

    firstUpdated(){
        return // FIXME
        let map = this.getRootNode().host.map
        
        map.on('move', e=>{
            this.locating = false
        });
    }

    async locateMe(){
        
        if( this.locating ) return
        
        this.locating = true
        
        let nid = 'locate-me'
        let n = null

        let thinking = setTimeout(()=>{
            n = Notif.sticky('Finding location...', {nid})
        }, 300)

        try{
            let coords = await getLocation()
            
            clearTimeout(thinking)

            let map = this.getRootNode().host.map
            if( map && this.locating )
                map.flyTo({
                    center: [coords.longitude, coords.latitude],
                    duration: 0,
                });

            n&&n.close()

        }catch(err){
            clearTimeout(thinking)
            console.log(err);
            Notif.error(err, {nid})
        }

        this.locating = false
    }

})

export default customElements.get('sr-location')

export function getLocation(){
    return new Promise((resolve, reject)=>{

        if( !navigator.geolocation )
            return reject('Geolocation is not supported by your browser')

        navigator.geolocation.getCurrentPosition(position=>{
            resolve(position.coords)
        }, err=>{
            reject(err.message)
        });

    })
}