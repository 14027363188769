import { LitElement, html, css } from 'lit'
import RoutedView from 'bui/app/views/routed'
import Panel from 'panel'
import device from 'bui/util/device'
import coll from './models'
import {isLandscape} from '../map/util'

Panel.register('m-report', {
    closeBtn: 'arrow',     
    // anchor: 'center',
    anchor: !isLandscape() ? 'bottom' : 'right',
    height: !isLandscape() ? '100%' : '100%',
    width: !isLandscape() ? '100%' : '600px',
})

customElements.define('m-report', class extends RoutedView {

    static title = 'Report'
    static icon = ''
    static path = 'report/:id'

    static styles = [RoutedView.styles, css`
        main {
            padding: 0 !important;
        }
    `]

    constructor(){
        super(...arguments)
        this.coll = coll
    }

    // updateTitle(){
    //     if( this.panel )
    //         this.panel.title = this.model ? this.model.get('title') : this.constructor.title
    // }

    // onModelChange(){
    //     this.updateTitle()
    // }

    modelRequired = true
    loadFetchData = 'with=files'

    onClose(){
        super.onClose()
        this.model = null
    }

    render(){return html`
        <b-panel-toolbar>
            
            <b-copy-btn slot="right" text icon="export" label="Share url" .value=${location.href}></b-copy-btn>
            
        </b-panel-toolbar>
        <main>
            <m-reports-row .model=${this.model} noshadow fullsize></m-reports-row>
        </main>
    `}

})

export default customElements.get('m-report')