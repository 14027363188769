import {Collection, Model} from 'backbone'
import FileModel from 'bui/components/filemanager/model'
import user from '../user'

class Reports extends Collection {
    url = '/api/reports'
    get model(){ return Report }
}

export default new Reports()

class Report extends Model {

    name = 'report'

    get isMine(){ return this.get('uid') == user.id }

    get collections(){ return {
        files: Files
    }}

    get hasGPS(){ return this.get('files').hasGPS }
}

class Files extends Collection {
    get url(){ return this.parentModel.url()+'/files'}
    get model(){ return File }

    get hasGPS(){ return !!this.find(m=>m.hasGPS)}
}

class File extends FileModel {

    get date(){
        let date = this.get('traits').exif?.image?.ModifyDate // NOTE: only location?
        return date
    }

    get make(){
        return this.get('traits').exif?.image?.Make || ''
    }

    get model(){
        return this.get('traits').exif?.image?.Model || ''
    }

    get hasGPS(){
        return this.get('traits')?.exif?.gps
    }

    get shareGPS(){
        return this.get('traits')?.shareGPS
    }

    lngLat(){
        let gps = this.get('traits')?.exif?.gps

        if( !gps ) return null

        let lngDir = gps.GPSLongitudeRef == 'W' ? -1 : 1
        let latDir = gps.GPSLongitudeRef == 'S' ? -1 : 1

        // https://gis.stackexchange.com/a/273402
        let lng = (gps.GPSLongitude[0]+(gps.GPSLongitude[1]/60)) * lngDir
        let lat = (gps.GPSLatitude[0]+(gps.GPSLatitude[1]/60)) * latDir

        return [lng, lat]
    }
}