import { LitElement, html, css } from 'lit'
import 'bui/components/charts/echart'
import colorScheme from 'bui/util/color-scheme'
import dayjs from 'dayjs'
import {changeHSL} from 'bui/util/color-shift'

customElements.define('m-weather-forecase-chart', class extends LitElement{

    static properties = {
        type: {type: 'String'},
        unit: {type: 'String'},
    }

    static styles = css`
        :host {
            display: block;
            position:relative;
        }

        :host([hidden]) { display: none; }

        b-echart {
            z-index: 0 !important;
            height: 200px;
        }

        [part="label"] {
            margin: 1em 1em -1em;
        }
    `

    get isDarkMode(){ return colorScheme.isWhatTheme() == 'dark' }

    render(){return html`
        <b-echart part="chart" @chart-click=${this.onChartClick}></b-echart>
    `}

    get chart(){ return this.$$('b-echart', true) }

    onCollChange(){
        this.refresh()
    }

    firstUpdated(){
        this.refresh()
    }

    fullscreen(){
        this.chart.requestFullscreen()
    }

    onChartClick(e){
        e.stopPropagation()
        
        // let params = e.detail
        // let data = this.chartData?.get(params.name)
        // this.emitEvent('chart-click', {data, params})
    }

    refresh(){
        this.chart?.set(this.createChartData())
    }

    createChartData(){

        let data = this.coll || []

        if( this.hidden = !data.length ) return {}
        
        // let axisLabels = this.coll.date.map(d=>d.toDate())
        let axisLabels = this.coll.map(d=>d.date)
        let color = getComputedStyle(this).getPropertyValue('--theme').trim()
        let series = []
        let darkMode = this.isDarkMode

        series.push({
            name: 'Temp',
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            color,//: this.isDarkMode ? '#fff' : '#181a1c',
            data: data.map(d=>d.temp)
        })

        series.push({
            name: 'Snow Min',
            type: 'bar',
            yAxisIndex: 1,
            color: '#ccc',//: this.isDarkMode ? '#fff' : '#181a1c',
            data: data.map(d=>d.snow[0])
        })

        series.push({
            name: 'Snow Max',
            type: 'bar',
            yAxisIndex: 1,
            color,//: this.isDarkMode ? '#fff' : '#181a1c',
            data: data.map(d=>d.snow[1])
        })

        let visualMap = {
            show: false,
            seriesIndex: 0,
            pieces: [
                { // freezing
                    gt: -100,
                    lte: 32,
                    color: '#00E5FF'
                },
                { // getting hot!
                    gt: 90,
                    lte: 120,
                    color:'#FF1744'
                }
            ],
            outOfRange: {
                color: color
            }
        }

        return {
             grid: {
                left: 40,
                top: 40,
                right: 40,
                bottom: 20
            },
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    // return params
                    return /*html*/`
                        <b-text xbold block>${dayjs(params[0].name).format('ddd')}</b-text>
                        ${params.map(d=>/*html*/`
                            <b-flex>
                                <b-text bold>${d.seriesName}</b-text>
                                <b-text>${d.value}</b-text>
                            </b-flex>
                        `).join(' ')}
                    `
                }
            },
            xAxis: {
                type: 'category',
                data: axisLabels,
                axisLabel: {
                    // attempt to only show each new day
                    interval: 1,
                    formatter: (value)=>{
                        return dayjs(value).format('ddd')
                    }
                }
            },
            yAxis: [{
                type: 'value',
                name: 'Temp',
                position: 'left',
                axisLabel: {
                    formatter: '{value} °F'
                },
                // min: 'dataMin',
                min: (val)=>val.min - 10,
            },{
                type: 'value',
                name: 'Snow',
                position: 'right',
                max: val=>val.max>12?val.max:12,
                axisLabel: {
                    formatter: '{value} in'
                },
                splitLine: {
                    show: false
                }
            }],
            visualMap,
            series,
        }
    }
})