import { LitElement, html, css } from 'lit'
import 'bui/helpers/lit/emitEvent'
import './hourly'
import './forecast-chart'

customElements.define('c-weather-forecast', class extends LitElement{

    static get properties(){ return {
        lng: {type: Number, reflect: true},
        lat: {type: Number, reflect: true},
        autoLoad: {type: Boolean}
    }}

    static get styles(){return css`
        :host {
            display: block;
            position:relative;
        }

        .period {
            /* line-height: 1.2em; */
            margin: 1em 0;
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 1em;
        }

        .period img {
            border-radius: 3px;
            margin-top: .25em;
            height: 86px;
            width: 86px;
            background-color: var(--theme-bgd-accent2);
        }

        [name="icicle"] {
            position: absolute;
            left: 1px;
            color: lightblue;
            top: 82%;
            font-size: 1.6em;
        }

        @media (max-width: 699px){
            .period:not(.show-details) .details {
                height: 2.5em;
                margin-bottom: -.5em;
                overflow: hidden;
                position: relative;
            }

            .period:not(.show-details) .details:before {
                content: '';
                position: absolute;
                bottom: 0;
                height: 1.25em;
                width: 100%;
                background: linear-gradient(to bottom, rgba(var(--theme-bgd-rgb),0), var(--theme-bgd) 85%);
            }
        }
    `}

    updated(...args){
        if( this.autoLoad !== false )
            this.refreshData()
        else{
            this.data = null
            this.removeMapLayer()
            this.update()
        }
    }

    async refreshData(lng, lat){

        this.data = null
        this.removeMapLayer()
        this.update()

        if( !this.lng || !this.lat ) return

        this.addMapLayer()
        this.data = await fetch(`/api/weather/forecast/${this.lng},${this.lat}`).then(r=>r.json())

        this.data.forecast?.forEach(d=>{
            d.snow = this.snowInches(d.details, true)
        })
        this.emitEvent('loaded', this.data)

        this.update()
        return this.data
    }

    async addMapLayer(){

        await mapView.loaded

        this.removeMapLayer()
        
        if( window.mapView?.map ){

            mapView?.map.addSource('weather-point', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': [{
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [this.lng, this.lat]
                        }
                    }]
                }
            })
 
            mapView?.map.addLayer({
                'id': 'weather-point',
                'type': 'circle',
                'source': 'weather-point',
                'paint': {
                    'circle-radius': 14,
                    'circle-color': '#FF5252'
                }
            });
        }
    }

    removeMapLayer(){
        if( window.mapView?.map && mapView.map.getLayer('weather-point') ){
            mapView.map.removeLayer('weather-point')
            mapView.map.removeSource('weather-point')
        }
    }

    disconnectedCallback(){
        super.disconnectedCallback()
        this.removeMapLayer()
    }

    snowInches(str, raw=false){
        let matches = str.match(/snow accumulation of (\d+) to (\d+) inches /)
        if( raw ) return matches ? [parseFloat(matches[1]), parseFloat(matches[2])] : [0,0]
        return matches ? `${matches[1]}-${matches[2]} in` :''
    }

    icon(url){
        return url.replace('http:', 'https:')
    }

    render(){return html`
        ${this.data?html`

            <b-flex>

                <b-text md xbold>7 Day Forecast</b-text>

                <b-btn outline color="theme" @click=${this.viewHourlyWeather}>Hourly</b-btn>

            </b-flex>

            <m-weather-forecase-chart .coll=${this.data.forecast}></m-weather-forecase-chart>

            <br>
        
            ${this.data.forecast?.map(d=>html`

            <div class="period" @click=${this.toggleDetailed}>
                <div>
                    <img src=${this.icon(d.icon)}>
                </div>

                <div>

                    <b-flex>
                        <div>
                            <b-text block>
                                ${d.label}
                                <b-text tone="info" bold>${this.snowInches(d.details)}</b-text> 
                            </b-text>
                            <b-text md bold>${d.weather}</b-text>
                        </div>

                        <div style="flex-shrink: 0; position: relative">
                            <b-text lg xbold>${d.temp}<b-text nobold>˚</b-text></b-text>
                            <b-icon name="icicle" ?hidden=${d.temp>32}></b-icon>
                        </div>
                        

                    </b-flex>

                    <b-text muted="1" body block class="details">${d.details}</b-text>
                    
                </div>

            </div>
            `)}

            ${!this.data.forecast?html`
                <b-empty-state style="height: 300px">Problem getting the forecast. API could be down.</b-empty-state>
            `:''}

            <b-flex>
                <b-text muted sm italic link @click=${this.openWeather}>Forecast from weather.gov</b-text>
                <b-text muted sm><b-ts .date=${this.data.updated}></b-ts></b-text>
            </b-flex>
        

        `:this.lng||this.lat?html`
            ${this.autoLoad===false?html`
                <b-btn block clear color="theme" @click=${this.showWeather}>Show Weather</b-btn>
            `:html`
                <b-empty-state static sm>Weather loading...</b-empty-state>
            `}
        `:''}
    `}

    openWeather(){
        window.open(this.data.url)
    }

    async showWeather(e){
        e.currentTarget.spin = true
        await this.refreshData()
    }

    toggleDetailed(e){
        e.currentTarget.classList.toggle('show-details')
    }

    viewHourlyWeather(){
        goTo(`weather/hourly/${this.lng},${this.lat}`)
    }

})

export default customElements.get('c-weather-forecast')