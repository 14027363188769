import { LitElement, html, css } from 'lit'
import Dialog from 'dialog'
import Menu from 'menu'
import RoutedView from 'bui/app/views/routed'
import mapboxgl from 'mapbox-gl'
import device from 'bui/util/device'
import 'bui/elements/theme'
import store from 'bui/util/store'
import favorites from './favorites'
import featuresColl from './models'
import Marker from './marker'
import '../location'
import '../detail/index2'
import user, {FeatureTypes} from '../user'
import { flyTo, flyToIfNeeded, visibleBounds } from './util'
import {mediaQuery} from 'bui/util/mediaQueries'

const MapCoords = store.create('map-coords', {
    // lng: device.isMobile ? -122.50616649279709 : -122.61746591406899,
    // lat: device.isMobile ? 42.59140848229492 : 42.366370075507206,
    // zoom: device.isMobile ? 8 : 9,

    lng: device.isMobile ? -122.00483835964337 : -122.03026841450438,
    lat: device.isMobile ? 42.948888898641684 : 43.01653042178157,
    zoom: device.isMobile ? 7 : 7.5
})

import Weather from '../weather'

const MAP_STYLE = 'mapbox://styles/kjantzer/ckhib104v1k9d19pfa7byd6vj'
const MAP_STYLE_DARK = 'mapbox://styles/kjantzer/ckuja6r3hfcge18npbmfn7yue'

mapboxgl.accessToken = `pk.eyJ1Ijoia2phbnR6ZXIiLCJhIjoiY2toZ3R4YmNqMHZjOTJ6cGZ2bmR6cTh5OSJ9.Xt-P1NAXpWNFYQJhXKhrmA`

async function getLocation(){
    return new Promise((resolve, reject)=>{
        navigator.geolocation.getCurrentPosition(pos=>{
            resolve(pos)
        }, err=>{
            reject(err)
        })
    })
}

customElements.define('s-map', class extends RoutedView {

    static get title(){ return 'Map' }
    static get id(){ return 'map' }
    static get path(){ return 'map(/:lng,:lat(,:zoom))' }
    static get icon(){ return 'logo' }

    static get styles(){return css`
        :host {
            background-color: #adb39c;
            display: block;
            position:relative;
            width: 100vw;
            height: 100vh;
            max-height: none !important;
            user-select: none;
            overflow: hidden;
            -webkit-user-select: none; /* ios */
        }

        .mapboxgl-canvas-container {
            height: 100%;
        }

        .mapboxgl-control-container {
            display: none;
        }

        .shadow {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            /* box-shadow: 19px 0px 31px -18px var(--theme-shadow) inset; */
            /* box-shadow: 26px 0px 13px -18px var(--theme-shadow) inset; */
            box-shadow: 0 0px 10px 4px var(--theme-shadow) inset;
        }

        b-panel {
            background: none;
            --b-panel-shadow: var(--theme-shadow, rgba(0,0,0,0.2)) 0 3px 10px !important;
            --radius: 0;
        }

        svg {
            height: 2.5em;
            width: 2.5em;
            position: absolute;
            top: calc(.5em + env(safe-area-inset-top));
            left: .5em;
            color: var(--indigo-A700);
            background: rgba(255,255,255,.6);
            border-radius: 50%;
            padding: .25em;
        }

        [fab] {
            bottom: auto;
            top: calc(var(--safe-top) + 1rem);
        }

        [fab].my-loc {
            top: auto;
            bottom: calc(var(--safe-bottom) + 1rem);
        }

        @media (max-width: 599px) {
            [fab].my-loc {
                top: auto;
                bottom: calc(var(--safe-bottom) + 5rem);
            }   
        }

    `}

    load(id, attrs, state){

        if( state.props?.flyTo )
            setTimeout(()=>{
                this.flyTo(...state.props?.flyTo)
            }, this.map?0:500)
    }

    constructor(...args){
        super(...args)
        this.loaded = new Promise((resolve)=>{
            this._loadedPromiseResolve = resolve
        })

        window.mapView = this

        user.on('change:settings', this.applyMapStyle, this)
        user.get('settings').on('change:features', this.applyFeatures, this)

        window.addEventListener('background-resume', e=>{
            // refetch features to get updated air and possible cached snotel data
            this.applyFeatures({refetch: true})
        })
    }

    applyMapStyle(...args){
        setTimeout(()=>{
            
            let mapStyle = user.mapStyle == 'dark' ? MAP_STYLE_DARK : MAP_STYLE

            let theme = this.$$('b-theme')
            theme.mode = user.mapStyle == 'dark' ? 'dark' : 'light'
            
            if( mapStyle != this.mapStyle){
                this.map?.setStyle(mapStyle)
                this.mapStyle = mapStyle
            }
        })
    }

    applyFeatures({refetch=false}={}){
        // wait for fetch to finish

        let d = null

        if( featuresColl.length == 0 ){
            // show "loading" notif if takes more than half second
            this._featureFetchSlow = setTimeout(()=>{
                d = new Dialog({title: '', body: 'Fetching features...', color: 'inverse', btns: false})
                d.notif()
            }, 500)
        }

        return featuresColl.fetchSync({bounds: this.visibleBounds({ignoreUI: true}), once: !refetch}).then(_=>{
            // this must be done evertime the style changes
            clearTimeout(this._featureFetchSlow)

            if( d)
                d.close()

            addFeatures.call(this)
        })
    }

    firstUpdated(){
        // setTimeout(()=>{
            this.setupMap()
        // })
    }

    mainPOI(){
        let features = this.renderedFeatures()
        let overview = {volcano: false, mountain:false, park: false}
        
        return features.filter(d=>{
            if( overview[d.maki] == undefined || overview[d.maki] )
                return false
            
            return overview[d.maki] = true
        }).map(d=>d.name)
    }

    renderedFeatures({
        classLike=['park_like', 'landform'],
        rank=1,
    }={}){
        let features = this.map.queryRenderedFeatures(this.map.getBounds())

        features = features.filter(d=>!d.properties.cluster_id&&d.properties.name)
        features = features.map(d=>d.properties)
        
        if( rank ){
            if( !Array.isArray(rank) )
                rank = [rank, rank]
            
            rank = rank.sort((a,b)=>a-b)
        }   

        features = features
            .filter(d=>{
                if( classLike && !classLike.includes(d.class) )
                    return false
                    
                if( rank && (d.filterrank<rank[0] || d.filterrank>rank[1]) )
                    return false

                return true
            })
            .sort((a,b)=>{
                let rankDiff = a.filterrank-b.filterrank
                if( rankDiff != 0 ) return rankDiff

                let type = ['Nature Reserve', 'National Park']
                let typeDiff = type.indexOf(a.type) - type.indexOf(b.type)
                
                if( typeDiff != 0 ) return typeDiff

                // taller mountains first
                return (a.elevation_ft - b.elevation_ft) * -1
            })

        return features
    }

    updateMapURL(){
        clearTimeout(this._updateMapURL)

        this._updateMapURL = setTimeout(()=>{

            let coords = this.map.getCenter()
            coords.zoom = Math.round(this.map.getZoom()*100)/100

            // fetch features for new visible area
            this.applyFeatures()

            MapCoords(coords)

            window.emitEvent('map:area-changed', {map: this.map, bounds: this.map.getBounds()})

            if( this.tabView.route.state){

                let poi = this.mainPOI()
                let title = (poi && poi.length > 0) ? poi.join(', ') : this.title

                this.tabView.route.state.update({
                    title,
                    path: this.tabView.route.makePath(coords)
                })
            }

        },500)
    }

    // NOTE: there's some ugly/hacky functional programming here that should be cleaned up
    setupMap(){

        if( this.map ) return

        let pos = Object.assign(MapCoords(), this.tabView.route.state?.params)

        this.mapStyle = user.mapStyle == 'dark' ? MAP_STYLE_DARK : MAP_STYLE
        this.map = new mapboxgl.Map({
            container: this,
            style: this.mapStyle,
            center: [pos.lng, pos.lat],
            zoom: pos.zoom
        });

        this.map.dragRotate.disable();
        this.map.touchZoomRotate.disableRotation();

        this.map.flyToIfNeeded = flyToIfNeeded

        function cancelShowWeather(){
            clearTimeout(showWeather)
            showWeather = null
        }

        let showWeather
        let mouseDown = false
        this.map.on(device.isMobile?'touchstart':'mousedown', e=>{
            clearTimeout(showWeather)
            showWeather = setTimeout(()=>{
                mouseDown = true

                // let other views disable this default action
                if( window.willTakeAction('map:longpress', e).allowed ){
                    goTo(`detail/weather@${e.lngLat.lng},${e.lngLat.lat}`)
                    cancelShowWeather()
                }
                
            }, 500)
        });

        this.map.on('click', (e)=>{

            if( mouseDown ){
                mouseDown = false
                return
            }

            const features = this.map.queryRenderedFeatures(e.point);
            let symbol = features.find(f=>f.layer.type=='symbol')

            let fav = features.find(f=>['favorites'].includes(f.layer.source))
            
            if( fav ){
                let props = fav.properties
                if( props.type == 'weather' )
                    goTo(`detail/weather@${props.lng},${props.lat}`)
            }

            if( symbol ) return console.log('clicked symbol', features);
            if( showWeather ) return console.log('showing weather');

            console.log('close detail if open');
            window.detailView?.close()
            window.emitEvent('map:clicked', e)
        })

        this.map.on(device.isMobile?'touchend':'mouseup', cancelShowWeather)
        this.map.on('movestart', cancelShowWeather)
        this.map.on('zoomstart', cancelShowWeather)

        this.map.on('touchevent', e=>{ this.updateMapURL() })
        this.map.on('dragend', e=>{ this.updateMapURL() })
        this.map.on('zoomend', e=>{ this.updateMapURL() })
        
        featuresColl.realtimeSync.open()

        this.map.on('style.load', () => {
            
            this.applyFeatures()
            let map = this.map

            this._loadedPromiseResolve()
            favorites.mapView = this.map
        })
        
    }

    render(){return html`
        <slot></slot>
        <div class="shadow"></div>

        <b-theme mode=${user.mapStyle}></b-theme>

        <b-btn fab color="theme-gradient" icon="layers" @click=${this.adjustLayers} tooltip="Features"></b-btn>
        <b-btn fab color="theme-gradient" class="my-loc" icon="my_location" @click=${this.goToMyLocation} tooltip="My location"></b-btn>

        <b-panels name="map"></b-panels>
    `}

    async goToMyLocation(){
        let d = Dialog.waiting({title: null, body: 'Finding location...'})
            d.notif()
        try{
            if( navigator.geolocation ){
                
                let pos = await getLocation()
                let zoom = this.map.getZoom()

                this.flyTo(pos.coords.longitude, pos.coords.latitude, zoom<=10?11:null)
                
            }else{
                throw new UIWarningError('GeoLocation not supported')
            }
        }catch(err){
            throw new UIWarningError(err)
        }finally{
            d.close()
        }

    }

    viewReports(){
        goTo('reports')
    }

    flyTo(lng, lat, zoom){
        flyTo.call(this.map, lng, lat, zoom)
    }

    visibleBounds(){
        return visibleBounds.call(this.map, ...arguments)
    }

    async adjustLayers(e){
        
        let menu = [{heading: 'Features'}]

        if( !user.id )
            menu.push({text: 'Login to adjust visible features', bgd: false})
        
        menu = menu.concat(FeatureTypes.map(d=>{
            if( d == '-' ) return d

            return {
                ...d,
                disabled: !user.id // disable if not logged in
            }
        }))

        // allow manual refetch without refreshing whole app
        // background-resume should do this for us, but let user do it too
        menu.push('-', {
            label: 'Refetch', val: 'fetch', clearsAll: true, icon: 'refresh',
            description: html`Last fetched <b-ts .date=${featuresColl.hasFetched}></b-ts>`
        })

        window.featuresColl = featuresColl // TEMP

        new Menu(menu, {
            selected: user.get('settings.features'),
            multiple: 'always',
            onSelect: (selected)=>{

                if( selected?.[0].val == 'fetch' ){
                    return this.applyFeatures({refetch: true})
                }

                if( !selected || !user.id) return

                selected = selected.map(d=>d.val).filter(d=>d)
                
                // TODO: only save if value changed
                user.get('settings').saveSync({features: selected.length==0?null:selected}, {patch: true})

            }
        }).popOver(e.currentTarget, {align: 'bottom-end'})
    }

})

export default customElements.get('s-map')





function addFeatures(){

    let map = this.map

    // AvalancheData.shared.map = this.map
    // TODO: rename source to 'features'
    if( this.map.getSource('snotels') ){
        this.map.getSource('snotels').setData(featuresColl.toGeoJSON())
        return
    }

    this.map.addSource('snotels', {
        type: 'geojson',
        //data:'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson', //stationsGeoJSON,
        data: featuresColl.toGeoJSON(), //'/api/snotel/geojson',
        cluster: true,
        clusterMaxZoom: 11, // Max zoom to cluster points on
        clusterRadius: 40 // Radius of each cluster when clustering points (defaults to 50)
    });

    this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'snotels',
        filter: ['has', 'point_count'],
        paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            // 'circle-color': [
            //     'step',
            //     ['get', 'point_count'],
            //     '#212121',
            //     4, '#E0E0E0',
            //     10, '#EEEEEE',
            //     //10, '#9E9E9E',
            //     // 15, '#E0E0E0',
            //     20,'#ffffff'
            // ],
            // 'text-color': '#000',
            'circle-color': '#212121',
            'circle-radius': 3,
            // 'circle-stroke-color': '#212121',
            'circle-stroke-width': 1,
            // 'circle-opacity': 0.5,
            'circle-radius': [
                'step',
                ['get', 'point_count'],
                8,
                2, 12,
                10, 20,
                15, 28, 
                25, 32
            ]
        }
    });

    this.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'snotels',
        filter: ['has', 'point_count'],
        layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 16
        },
        paint: {
            'text-color': '#ffffff'
        }
    });

    // inspect a cluster on click
    this.map.on('click', 'clusters', (e) => {

        e.preventDefault()
        e.originalEvent.stopPropagation()

        const features = map.queryRenderedFeatures(e.point, {
            layers: ['clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        map.getSource('snotels').getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
                if (err) return;

                map.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: zoom > 9 ? zoom : 9
                });
            }
        );
    });

    // When a click event occurs on a feature in
    // the unclustered-point layer, open a popup at
    // the location of the feature, with
    // description HTML from its properties.
    this.map.on('click', 'unclustered-point', (e) => {

        console.log(e.features.properties);

        // const coordinates = e.features[0].geometry.coordinates.slice();
        // const mag = e.features[0].properties.mag;
        // const tsunami =
        //     e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

        // // Ensure that if the map is zoomed out such that
        // // multiple copies of the feature are visible, the
        // // popup appears over the copy being pointed to.
        // while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        //     coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        // }

        // new mapboxgl.Popup()
        //     .setLngLat(coordinates)
        //     .setHTML(
        //         `magnitude: ${mag}<br>Was there a tsunami?: ${tsunami}`
        //     )
        //     .addTo(map);
    });


    // after the GeoJSON data is loaded, update markers on the screen on every frame
    map.on('render', () => {
        if (!map.isSourceLoaded('snotels')) return;
        updateMarkers.call(this)
    });

}


// objects for caching and keeping track of HTML marker objects (for performance)
const markers = {}
let markersOnScreen = {};

function updateMarkers(){

    const map = this.map
    const newMarkers = {};
    const features = map.querySourceFeatures('snotels');

    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (const feature of features) {

        const coords = feature.geometry.coordinates;
        const props = feature.properties;
        
        if( props.cluster ) continue;
        const id = feature.id;

        let marker = markers[id];
        if (!marker) {

            // const el = document.createElement('b-icon')
            // marker = markers[id] = new mapboxgl.Marker({
            //     element: el
            // }).setLngLat(coords);

            // let MarketEl = {
            //     snotel: MarkerSnotel,
            //     cam: MarkerCam
            // }[props.type] || MarkerSnotel

            let markerEl = new Marker(map, props, featuresColl.get(feature.id));

            marker = markers[id] = markerEl.marker = new mapboxgl.Marker({element:markerEl})
                .setLngLat(coords)

        }

        newMarkers[id] = marker;

        if (!markersOnScreen[id]) marker.addTo(map);
    }

    // for every marker we've added previously, remove those that are no longer visible
    for (const id in markersOnScreen) {
        if (!newMarkers[id]) markersOnScreen[id].remove();
    }
    
    markersOnScreen = newMarkers;
}
