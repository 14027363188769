import Chart, {Plugins, Ticks} from './chart'
import dayjs from 'dayjs'

customElements.define('s-chart-temp', class extends Chart{

    get options(){return {
        scales: {
            x: {
                ticks: {
                    callback: Ticks.byHour,
                    font: {size: 14}
                },
            },
            y: {
                suggestedMin: 10,
            }
        }
    }}

    get plugins(){ return [
        Plugins.addPointLabels({suffix:'˚'})
    ]}

    get chartData(){
        return {
            labels: this.data.time.map(t=>dayjs(t)),
            datasets: [{
                label: 'Temp',
                data: this.data['temperature:hourly'],
                borderColor: '#FF1744'
            },{
                label: 'Dew Point',
                data: this.data['temperature:dew point'],
                borderColor: '#4DB6AC'
            }]
        }
    }

})
