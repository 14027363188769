import { LitElement, html, css } from 'lit'
import 'bui/elements/empty-state'
import 'bui/elements/paper'
import 'bui/util/sum'
import device from 'bui/util/device'
import Panel from 'panel'

customElements.defineShared('sr-weather', class extends LitElement{

    static get styles(){return css`
        :host {
            display: block;
            position:relative;
            /* width: 400px; */
            /* height: 400px; */
            /* max-width: 100%; */
            /* max-height: 100%; */
            /* overflow: auto; */
        }

        main {
            position: relative;
        }

        :host(.panel) {
            display: grid;
            grid-template-rows: auto 1fr;
            overflow: hidden;
            height: 100%;
        }

        :host(.panel) main {
            overflow: auto;
            padding: 0 1em 1em 1em;
        }

        .period {
            line-height: 1.2em;
            margin: 1em 0;
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 1em;
        }

        img {
            border-radius: 3px;
            margin-top: .25em;
        }

        @media (max-width: 699px){
            .period:not(.show-details) .details {
                height: 1.75em;
                overflow: hidden;
            }
        }
        /* @media (max-width: 699px){ */
            sr-weather-new-snow {
                margin-top: .5em;
            }
        /* } */
    `}

    async open(lat, long, map){

        this.classList.add('panel')

        this.panel = new Panel(this,{
            title: `Weather`,
            closeBtn: 'arrow',
            anchor: 'bottom',
            height: '65vh', //device.isMobile ? '65vh' : '55vh',
            onClose: ()=>{
                this.panel = null
                this.classList.remove('panel')
                
                if( map && this.forecast ){
                    map.removeLayer('weather')
                    map.removeSource('weather')
                }

                return true
            }
        }).open()

        await this.load(lat, long)

        if( !this.forecast )
            return this.panel.title = 'Error'

        this.panel.title = Math.round(this.forecast.properties.elevation.value * 3.28084)+'ft'

        if( map ){
             map.addSource('weather', {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'geometry': this.forecast.geometry
                }
             })

             map.addLayer({
                'id': 'weather',
                'type': 'fill',
                'source': 'weather',
                'layout': {},
                'paint': {
                    'fill-color': '#304FFE',
                    'fill-opacity': 0.6,
                    'fill-outline-color': '#304FFE'
                }
            });
        }
    }

    async load(lat, long){

        this.error = null
        this.forecast = null
        this.snowSummaryData = null
        this.update()

        this.forecast = await fetch(`/weather/${lat},${long}`).then(r=>r.json())

        if( !this.forecast || !this.forecast.properties ){
            this.error = this.forecast.detail || 'Error'
            this.forecast = null
        }

        let summary = this.snowSummary()

        this.update()

        return summary
    }

    snowInches(str, raw=false){
        let matches = str.match(/snow accumulation of (\d+) to (\d+) inches /)
        if( raw ) return matches ? [parseFloat(matches[1]), parseFloat(matches[2])] : [0,0]
        return matches ? `${matches[1]}-${matches[2]} in` :''
    }

    snowSummary(){

        if( !this.forecast ) return ''

        let low = []
        let high = []

        this.forecast.properties.periods.forEach(d=>{

            let [l, h] = this.snowInches(d.detailedForecast, true)

            low.push(l)
            high.push(h)
        })

        this.snowSummaryData = {
            low: {
                three: low.slice(0,3).sum(),
                five: low.slice(0,5).sum(),
                ten: low.slice(0,10).sum(),
                raw: low
            },
            high: {
                three: high.slice(0,3).sum(),
                five: high.slice(0,5).sum(),
                ten: high.slice(0,10).sum(),
                raw: high
            }
        }

        // this.emitEvent('weather-new-snow', this.snowSummaryData)

        return this.snowSummaryData
    }


    toggleDetailed(e){
        e.currentTarget.classList.toggle('show-details')
    }

    render(){return html`

        ${this.panel?html`
            <b-panel-toolbar></b-panel-toolbar>

            
        `:''}

        <main>

        ${this.snowSummaryData?html`
            <sr-weather-new-snow .data=${this.snowSummaryData}></sr-weather-new-snow>
        `:''}

        ${this.forecast&&this.forecast.properties.periods.map(d=>html`

            <div class="period" @click=${this.toggleDetailed}>
                <div>
                    <img src=${d.icon}>
                </div>

                <div>
                    <b-text md block>
                        ${d.name}
                        <b>${d.temperature}˚</b>
                        <b-text tone="info">
                            ${this.snowInches(d.detailedForecast)}
                        </b-text>
                    </b-text>
                    
                    <b-text italic>${d.shortForecast}</b-text>

                    <b-text muted block class="details">${d.detailedForecast}</b-text>
                    
                </div>

            </div>
        `)}

        ${this.forecast&&this.forecast.properties.periods?html`
        <b-text muted sm italic>Forecast from weather.gov</b-text>
        <br>
        `:''}

        <b-empty-state md must-be="first">
            ${this.error||'Loading Weather...'}
        </b-empty-state>

        </main>
    `}

})

export default customElements.get('sr-weather')


customElements.define('sr-weather-new-snow', class extends LitElement{

    static get styles(){return css`
        :host {
            display: grid;
        }

        .new-snow {
            background-color: var(--theme-bgd-accent);
        }

        b-grid {
            grid-template-columns: repeat(var(--cols, 3), 1fr);
        }

        @media (max-width: 699px) {
            .new-snow {
                padding: .75em;
            }
        }
    `}

    render(){return html`
        <b-paper class="new-snow" outline>
            <b-text muted bold ucase>New Snow (in)</b-text>

            <b-grid cols=3 gap=".5">
                ${['three', 'five', 'ten'].map(k=>html`
                <div>
                    <b-text xl>
                        ${this.data.low[k]}-${this.data.high[k]} 
                        <!-- <b-text muted>in</b-text> -->
                    </b-text>
                    <b-text block sm italic muted>${k} days</b-text>
                </div>
                `)}
            </b-grid>
        </b-paper>
    `}

})