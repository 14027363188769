import { LitElement, html, css } from 'lit'
import 'bui/components/charts/echart'
import colorScheme from 'bui/util/color-scheme'
import dayjs from 'dayjs'

customElements.define('m-feature-detail-snotel-chart', class extends LitElement{

    static properties = {
        type: {type: 'String'},
        unit: {type: 'String'},
    }

    static listeners = {
        coll: {'sync': 'collSynced'}
    }

    collSynced(){
        this.refresh()
        this.requestUpdate()
    }

    static styles = css`
        :host {
            display: block;
            position:relative;
        }

        :host([hidden]) { display: none; }

        b-echart {
            z-index: 0 !important;
            height: 200px;
        }

        [part="label"] {
            margin: 1em 1em -1em;
        }
    `

    get isDarkMode(){ return colorScheme.isWhatTheme() == 'dark' }

    render(){return html`
        <b-echart part="chart" @chart-click=${this.onChartClick}></b-echart>
    `}

    get chart(){ return this.$$('b-echart', true) }

    onCollChange(){
        this.refresh()
    }

    firstUpdated(){
        this.refresh()
    }

    fullscreen(){
        this.chart.requestFullscreen()
    }

    onChartClick(e){
        e.stopPropagation()
        
        // let params = e.detail
        // let data = this.chartData?.get(params.name)
        // this.emitEvent('chart-click', {data, params})
    }

    refresh(){
        this.chart?.set(this.createChartData())
    }

    createChartData(){

        let data = this.coll?.[this.type] || []

        if( this.hidden = !data.length ) return {}
        
        // let axisLabels = this.coll.date.map(d=>d.toDate())
        let axisLabels = this.coll.date.map(d=>d.format('YYYY-MM-DD HH:mm:00 Z'))
        let color = getComputedStyle(this).getPropertyValue('--theme').trim()
        let series = []
        let darkMode = this.isDarkMode

        // only last 7 days
        // axisLabels = axisLabels.slice(24 * -7)
        // data = data.slice(24 * -7)

        series.push({
            name: 'Data',
            type: 'line',
            // smooth: true,
            // smoothMonotone: 'x',
            color,//: this.isDarkMode ? '#fff' : '#181a1c',
            data: data
        })

        let marks = []

        axisLabels.forEach((k,i)=>{
            if( i%48 == 0 )
                marks.push([
                    {xAxis: k},
                    {xAxis: axisLabels.at(i+24) || axisLabels.at(-1)},
                ])
        })

        series.push({
            name: 'mark',
            type: 'line',
            silent: true,
            markArea: {
                data: marks,
                itemStyle: {
                    color: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(93, 64, 55, 0.05)'
                }
            }
        })

        let visualMap = null

        // highlight temps
        if( this.type == 'air_temperature_observed')
            visualMap = {
                show: false,
                pieces: [
                    { // freezing
                        gt: -100,
                        lte: 32,
                        color: '#00E5FF'
                    },
                    { // getting hot!
                        gt: 90,
                        lte: 120,
                        color: '#FF1744'
                    }
                ],
                outOfRange: {
                    color: color
                }
            }
        

        let unit = this.unit || ''

        return {
             grid: {
                left: 40,
                top: 20,
                right: 20,
                bottom: 20
            },
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    return `${dayjs(params[0].name).format('ddd ha')}: ${params[0].value||'-'} ${unit}`;
                }
            },
            xAxis: {
                type: 'category',
                data: axisLabels,
                axisLabel: {
                    // attempt to only show each new day
                    interval: 44,
                    formatter: (value)=>{
                        return dayjs(value).format('MMM D')
                    }
                }
            },
            yAxis: {
                type: 'value',
                // min: 'dataMin',
                // min: (val)=>val.min - Math.max(Math.round(val.min*.005), 1),
                // axisLabel: {
                //     formatter: function (value, index) {
                //         return index === 0 ? '' : value;
                //     }
                // }
            },
            visualMap,
            _visualMap: {
                show: true, // You can set this to true if you want to display the visualMap component
                min: 25,
                max: 110,
                inRange: {
                    color: ['#FF1744', '#FF1744'] // From blue to red, adjust as needed
                }
            },
            series,
            dataZoom: [
                {
                    type: 'inside',
                    realtime: true,
                    startValue: axisLabels.length - (24*7),
                    endValue: axisLabels.length-1,
                    minSpan: Math.round((24*2) / axisLabels.length * 100),
                    zoomOnMouseWheel: 'shift',
                }
            ]
        }
    }
})