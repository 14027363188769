import Chart, {Plugins, Ticks} from './chart'
import dayjs from 'dayjs'

customElements.define('s-chart-clouds', class extends Chart{

    get options(){return {
        scales: {
            x: {
                ticks: {
                    callback: Ticks.byHour,
                    font: {size: 14},
                    padding: 20
                },
            },
            y: {
                suggestedMin: 10,
            }
        }
    }}

    get plugins(){ return [
        Plugins.addPointLabels({suffix:'%'})
    ]}

    get chartData(){
        return {
            labels: this.data.time.map(t=>dayjs(t)),
            datasets: [{
                label: 'Cloud Cover',
                data: this.data['cloud-amount:total'],
                borderColor: '#78909C'
            },{
                label: 'Precp.',
                data: this.data['probability-of-precipitation:floating'],
                borderColor: '#2962FF'
            }]
        }
    }

})

