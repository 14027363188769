import Notif from 'notif'

let loadedVersion
let lastFetched

function getVersion(){
    lastFetched = new Date()
    return fetch('/version').then(r=>r.json())
}

getVersion().then(ver=>{
    loadedVersion = ver
})


window.addEventListener('background-resume', async e=>{

    let currentVersion = await getVersion()

    console.log(loadedVersion, currentVersion);

    if( loadedVersion && currentVersion 
    && loadedVersion.commit != currentVersion.commit ){
        new Notif({
            icon: 'arrows-ccw',
            msg: 'New update available',
            anchor: 'top-right',
            autoClose: false,
            btns: [{label: 'Reload', color: 'theme'}],
            onClick: (notif, btn)=>{
                if( btn )
                    reload()
            }
        })
    }
    
})

function reload(){
    document.body.style.display = 'none'
    location.reload();
}
