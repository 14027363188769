import { LitElement, html, css } from 'lit'
import RoutedView from 'bui/app/views/routed'
import Panel from 'panel'
import user, {MapStyles, FeatureTypes} from './models'
import {Themes, ThemeColors} from 'bui/util/color-scheme'
import device from 'bui/util/device'
import AppInstaller from 'bui/app/installer'
import 'bui/elements/text'
import 'bui/elements/text-divider'
import 'bui/elements/avatar'
import 'bui/elements/grid'


// Panel.register('s-account', {closeBtn: 'arrow', _shortcut: true, width: '500px'})

customElements.define('s-account', class extends RoutedView {

    static title = 'Account'
    static icon = 'account_circle'
    static path = 'account'

    static tabBarBtn({tabView}){
        let model = tabView.view.model
        return html`
            ${model.id?html`<b-avatar url=${model.get('photo')} 
                initials=${model.initials}
                style="position: absolute; opacity: 1; width: 2em; top: 12%; left: 50%; transform: translateX(-50%);"
            ></b-avatar>`:''}

            <b-text sm bold>${model.id?model.firstName:'Account'}</b-text>
        `
    }

    static styles = [RoutedView.styles, css`
        
        main {
            width: 800px;
            padding-top: var(--gutter-safe-top) !important;
            margin: 0px auto;
            max-width: 100vw;
            box-sizing: border-box;
        }

        .header {
            justify-items: center;
        }

        b-avatar {
            --size: 6em;
        }

    `]

    constructor(){
        super()
        this.model = user
    }

    load(){}

    render(){return html`
        <b-panel-toolbar></b-panel-toolbar>
        
        <main>

            ${!this.model.id?html`

                <b-btn block lg color="theme" href="/login/google">Login with Google</b-btn>

            `:html`

                <b-grid cols="1" gap="1" center class="header">

                    <b-avatar url=${this.model.get('photo')} initials=${this.model.initials}></b-avatar> 

                    <b-text xbold xl align="center">${this.model.get('name')}</b-text>
                    
                    <b-btn text href="/logout">Logout</b-btn>
                </b-grid>
            `}

            <form-handler .model=${this.model.get('settings')} autosave="patch" 
                ?disabled=${!this.model.id}
                .retainModel=${true}
                .onChange=${this.onChange.bind(this)}>
            <b-grid cols-mobile=1>

                <b-text-divider colspan lg xbold pad>
                    Settings
                </b-text-divider>

                <b-text muted italic ?hidden=${this.model.id} colspan>Login to adjust settings</b-text>
            
                <form-control key="theme" material="filled" label="Theme">
                    <select-field .options=${Themes} show-empty></select-field>
                </form-control>

                <form-control key="theme_accent" material="filled" label="Accent">
                    <select-field .options=${ThemeColors} show-empty></select-field>
                </form-control>

                <form-control key="map_style" material="filled" label="Map Style" >
                    <select-field .options=${MapStyles} show-empty></select-field>
                </form-control>

                <form-control key="features" material="filled" label="Features" >
                    <select-field multiple .options=${FeatureTypes} show-empty></select-field>
                </form-control>
  
                <b-hr colspan thick short></b-hr>

                ${AppInstaller.canInstall?html`
                <b-text colspan align="center">
                    <b-btn clear lg color="theme" icon="${device.isMobile?'install_mobile':'install'}" 
                        @click=${AppInstaller.install}>Install App</b-btn>
                </b-text>
                `:''}

                
            
            </b-grid>
            </form-handler>


        </main>
    `}

     onChange(changes){

        if( changes.theme != undefined || changes.theme_accent != undefined ){
            this.model.applyTheme()
        }

        // window.emitEvent('settings-change', settings)
    }


})

export default customElements.get('s-account')