import { LitElement, html, css } from 'lit'
import 'bui/components/charts/echart'
import colorScheme from 'bui/util/color-scheme'
import dayjs from 'dayjs'

customElements.define('m-feature-detail-usgs-chart', class extends LitElement{

    static properties = {
        type: {type: 'String'},
        label: {type: 'String'}
    }

    static listeners = {
        coll: {'sync': 'collSynced'}
    }

    collSynced(){
        this.refresh()
        this.requestUpdate()
    }

    static styles = css`
        :host {
            display: block;
            position:relative;
        }

        :host([hidden]) { display: none; }

        b-echart {
            z-index: 0 !important;
            height: 200px;
        }

        [part="label"] {
            margin: 1em 1em -1em;
        }
    `

    get isDarkMode(){ return colorScheme.isWhatTheme() == 'dark' }

    render(){return html`
        <b-echart part="chart" @chart-click=${this.onChartClick}></b-echart>
    `}

    get chart(){ return this.$$('b-echart', true) }

    onCollChange(){
        this.refresh()
    }

    firstUpdated(){
        this.refresh()
    }

    fullscreen(){
        this.chart.requestFullscreen()
    }

    onChartClick(e){
        e.stopPropagation()
        
        // let params = e.detail
        // let data = this.chartData?.get(params.name)
        // this.emitEvent('chart-click', {data, params})
    }

    refresh(){
        this.chart?.set(this.createChartData())
    }

    createChartData(){
        let data = this.coll?.value || []

        this.hidden = !data.length
        
        let axisLabels = data.map(d=>d.ts)
        let color = getComputedStyle(this).getPropertyValue('--theme').trim()
        let series = []

        series.push({
            name: 'Data',
            type: 'line',
            // smooth: true,
            // smoothMonotone: 'x',
            color,//: this.isDarkMode ? '#fff' : '#181a1c',
            data: data.map(d=>d.val)
        })

        return {
             grid: {
                left: 40,
                top: 20,
                right: 20,
                bottom: 20
            },
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    return `${dayjs(params[0].name).format('MMM D H:mma')}: ${params[0].value}`;
                }
            },
            xAxis: {
                type: 'category',
                data: axisLabels,
                axisLabel: {
                    // attempt to only show each new day
                    interval: (24 * (60/5) - 5),
                    formatter: (value)=>{
                        return dayjs(value).format('MMM D')
                    }
                }
            },
            yAxis: {
                type: 'value',
                // min: 'dataMin',
                min: (val)=>val.min - Math.max(Math.round(val.min*.005), 1),
                axisLabel: {
                    formatter: function (value, index) {
                        return index === 0 ? '' : value;
                    }
                }
            },
            series,
        }
    }

})