/*
    Renders the SWE graph found at:
    https://www.nrcs.usda.gov/wps/portal/wcc/home/quicklinks/imap

    More graphs:
    https://www.nrcs.usda.gov/Internet/WCIS/AWS_PLOTS/siteCharts/POR/PREC/OR/Fish%20Lk..html
*/
import { LitElement, html, css } from 'lit'
import scrollbars from 'bui/helpers/scrollbars'
import Panel from 'panel'

customElements.defineShared('s-snotel-swe-graph', class extends LitElement{
    
    static get styles(){return css`
        :host {
            display: block;
            position:relative;
            min-height: 540px;
            height: 60vh;
            max-height: 640px;
            padding-bottom: var(--safe-bottom);
        }

        ${scrollbars.hide('main')}

        main {
            width: 100%;
            height: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            border-radius: var(--radius) var(--radius) 0 0;
        }

        iframe {
            --offset: -3em;
            width: var(--graph-width, 100%);
            min-width: 1000px;
            height: calc(100% - var(--offset));
            width: 100%;
            outline: none;
            border: none;
            margin-top: var(--offset);
        }

        b-panel-toolbar {
            background-color: var(--theme-bgd);
            border-radius: var(--radius) var(--radius) 0 0;
        }

        @media
        screen and (orientation:landscape) and (max-width:999px) and (max-height:599px)
        {
            iframe {
                --offset: -5em;
            }
        }
    `}

    open(model){
        this.model = model

        new Panel(this, {
            title: 'SWE for '+model.get('name'),
            anchor: 'bottom',
            height: 'auto',
            disableBackdropClick: false,
            closeOnEsc: true,
            closeBtn: 'arrow'
        }).open()

        this.update()
    }

    get url(){
        let name = this.model.get('name')
        let state = this.model.get('props').state
        return `https://www.nrcs.usda.gov/Internet/WCIS/AWS_PLOTS/siteCharts/POR/WTEQ/${state.toUpperCase()}/${encodeURIComponent(name)}.html`
    }

    render(){return html`
        <b-panel-toolbar overlay></b-panel-toolbar>
        <main>
            <iframe src="${this.url}" frameborder="0"></iframe>
        </main>
    `}

})

export default customElements.get('s-snotel-swe-graph')