import Panel from 'panel'
import ChartPanel, {html} from './chart-panel'
import './chart-temp'
import './chart-clouds'
import './chart-wind'

Panel.register('weather/hourly/:lng,:lat', 's-weather-hourly', {
    title: 'Hourly Forecast',
    anchor: 'bottom',
    height: 'auto',
    disableBackdropClick: false,
    closeOnEsc: true,
    closeBtn: 'arrow'
})

customElements.defineShared('s-weather-hourly', class extends ChartPanel {

    renderCharts(){return html`
        <s-chart-temp .data=${this.data} gutter title="Temperature"></s-chart-temp>

        <s-chart-clouds .data=${this.data} gutter title="Cloud & Precipitation"></s-chart-clouds>

        <s-chart-wind .data=${this.data} gutter title="Wind"></s-chart-wind>
    `}
})

export default customElements.get('s-weather-hourly')