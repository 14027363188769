import Icon from 'bui/elements/icon'
import PWAIcons from 'bui/elements/icon/sets/pwa'

Icon.register(
    require('bui/elements/icon/legacy/direction.svg.html'),
    require('bui/elements/icon/legacy/cancel-1.svg.html'),
    // require('bui/elements/icon/legacy/right-open.svg.html'),
    require('bui/elements/icon/legacy/link-ext.svg.html'),
    // require('bui/elements/icon/legacy/export.svg.html'),
    require('bui/elements/icon/legacy/star.svg.html'),
    require('bui/elements/icon/legacy/camera.svg.html'),
    require('bui/elements/icon/legacy/resize-full.svg.html'),
    require('bui/elements/icon/legacy/globe.svg.html'),
    require('bui/elements/icon/legacy/bug.svg.html'),
    require('bui/elements/icon/legacy/up.svg.html'),
    require('bui/elements/icon/legacy/arrows-ccw.svg.html'),
    ['info', require('@material-icons/svg/svg/info/baseline.svg')],
    ['logo', require('../public/icons/mountain.svg')],
    require('../public/icons/cam.svg'),
    require('../public/icons/snowflake.svg'),
    ['sensor', require('../public/icons/sensor.svg')],
    require('../public/icons/thermometer.svg'),
    ['icicle', require('../public/icons/icicle.svg')],
    ['resort', require('../public/icons/resort.svg')],
    ['account_circle', require('@material-icons/svg/svg/account_circle/baseline.svg')],
    ['play_arrow', require('@material-icons/svg/svg/play_arrow/baseline.svg')],
    ['browser_updated', require('@material-icons/svg/svg/browser_updated/baseline.svg')],
    ['system_update', require('@material-icons/svg/svg/system_update/baseline.svg')],
    ['layers', require('@material-icons/svg/svg/layers/baseline.svg')],
    ['more_vert', require('@material-icons/svg/svg/more_vert/baseline.svg')],
    ['add_box', require('@material-icons/svg/svg/add_box/baseline.svg')],
    ['burst_mode', require('@material-icons/svg/svg/burst_mode/baseline.svg')],
    ['location_on', require('@material-icons/svg/svg/location_on/baseline.svg')],
    ['warning', require('@material-icons/svg/svg/warning/baseline.svg')],
    ['block', require('@material-icons/svg/svg/block/baseline.svg')],
    ['water', require('@material-icons/svg/svg/water/baseline.svg')],
    ['my_location', require('@material-icons/svg/svg/my_location/baseline.svg')],

    // ['chevron_left', require(`@material-icons/svg/svg/chevron_left/baseline.svg`)],
    // ['chevron_right', require(`@material-icons/svg/svg/chevron_right/baseline.svg`)],
    ['export', require(`@material-icons/svg/svg/ios_share/baseline.svg`)],

    ...PWAIcons
    //...StandardIcons
)
