import { LitElement, html, css } from 'lit'
import Panel from 'panel'
import 'panel/fullscreen-btn'
import RoutedView from 'bui/app/views/routed'
import device from 'bui/util/device'
import {mediaQuery} from 'bui/util/mediaQueries'
import 'bui/elements/text'
import 'bui/elements/grid'
import Weather from '../weather'
import '../weather/forecast'
import '../snotel/data'
import features from '../map/models'
import favorites from '../map/favorites'
import 'bui/elements/copy-btn'

window.favorites = favorites // TEMP

// (min-width:900px) and (min-height: 600px), (min-width: 700px)
function isLandscape(){
    return (window.outerWidth>=900 && window.outerHeight >=600) || window.outerWidth >= 700 
}

// https://www.wcc.nrcs.usda.gov/siteimages/479.jpg

// Panel.register('detail/:type@:id(,:id2)', 's-detail', {
//     // controller: device.isSmall ? 'root': 'map',
//     anchor: !isLandscape() ? 'bottom' : 'right',
//     height: !isLandscape() ? '65vh' : '100%',
//     width: !isLandscape() ? '100%' : '600px',
//     title: 'Detail',
//     className: 'detail',
//     closeBtn: 'arrow'
// })

let ts = new Date().getTime()

customElements.defineShared('s-detail', class extends RoutedView{

    static get styles(){return css`
        :host {
            display: block;
            position:relative;
            width: 400px;
            max-width: 100%;
            /* min-height: 300px; */
            /* max-height: 30vh; */
            display: grid;
            grid-template-rows: auto 1fr;
            height: 100%;
            overflow: hidden;
        }
    
        main {
            padding: var(--gutter);
            padding-bottom: var(--gutter-safe-bottom);
            overflow: auto;
            padding-top: .5em;
        }

        .cams img {
            width: 100%;
            border-radius: 4px;
        }

        ${mediaQuery('b-app-landscape', css`
            [slot="close-btn"] {
                transform: none;
            }
        `)}

        [fav] {
            color: var(--amber-700);
        }
    `}

    willOpen(){ 
        this.panel.anchor = !isLandscape() ? 'bottom' : 'right'
        this.panel.height = !isLandscape() ? '65vh' : '100%'
        this.panel.width = !isLandscape() ? '100%' : '600px'

        // return mapView.loaded
    }

    onClose(){
        window.detailView = null
        // mapView.map.setPadding({
        //     right: 0,
        //     bottom: 0
        // })
    }

    onOpen(...args){
        // super.onOpen(...args)
        window.detailView = this
    }

    async load(id, attrs, props){
        
        if( isLandscape() ){
            window.mapView?.map.setPadding({
                right: 500,
                bottom: 0
            })
        }else{
            window.mapView?.map.setPadding({
                right: 0,
                bottom: Math.round(window.outerHeight * .6)
            })
        }

        if( (new Date().getTime() - ts) < 1000)
            window.mapView?.map.setZoom(10)
        
        this.anchor = isLandscape() ? 'bottom' : 'right'
        this.height = isLandscape() ? '65vh' : '100%'
        this.width = isLandscape() ? '100%' : '600px'

        let {type} = props.params

        if( type == 'weather' ){
            this.model = {
                type: 'weather',
                longitude: props.params.id,
                latitude: props.params.id2
            }
            this.panel.title = 'Weather'
            this.flyTo()
            return
        }

        let feature = await fetch.json(`/api/snotel/geojson/${type}@${id}`)

        if( !feature ){
            this.close()
            throw new UIWarningError('Could not find that feature')
        }

        this.feature = feature
        this.model = feature.properties
        this.panel.title = this.model.name || this.model.title
        this.flyTo()
        
        // this.route.state.update({
        //     title: this.model.name
        // })

        // // WeatherHourly.shared.load(this.model.longitude, this.model.latitude)
        // if( this.model.type == 'snotel' )
        // Weather.shared.load(this.model.latitude, this.model.longitude).then(newSnow=>{
            // this.newSnow = newSnow
            // this.update()
        // })

        // console.log('load this?', feature);

        
        

        // await mapView.loaded

        // let {type} = props.params
        // let features = mapView.map.querySourceFeatures('snotels')
        // let feature = features.find(d=>{
        //     console.log(d.properties.type, d.properties.id);
        //     return d.properties.type==type && d.properties.id==id
        // })

        // console.log(feature);
    }

    flyTo(){
        if( window.mapView?.map ){
            mapView.loaded.then(e=>{
                mapView?.flyTo(this.model.longitude, this.model.latitude)
            })
        }
    }

    onWeatherLoaded(e){
        if( this.model.type == 'weather' ){
            this.panel.title = 'Weather: '+e.detail.location
        }
    }

    toggleFavorite(){
        
        let route = this.panel.route
        let {type, id, id2} = route.params
        let path = route.currentPath

        if( favorites.get(path) )
            favorites.delete(path)
        else
            favorites.set(path, {type, lng: id, lat: id2})

        this.update()
    }

    get isFavorite(){
        return favorites.get(this.panel.route.currentPath)
    }

    share(){}

    render(){return html`
        
        <b-panel-toolbar>

            <b-flex right slot="right" gap="0" style="min-width: max-content;">

            ${device.isInstalled?html`
                <b-copy-btn _slot="right" text icon="export" label="Share url" .value=${location.href}></b-copy-btn>
            `:''}
            
            ${this.model?.type=='weather'?html`
                <b-btn _slot="right" text icon="star" ?fav=${this.isFavorite} @click=${this.toggleFavorite}></b-btn>
            `:''}

            ${device.isSmall?html`
                <b-panel-fullscreen-btn .panel=${this.panel} slot="" text></b-panel-fullscreen-btn>    
            `:''}

            </b-flex>
        </b-panel-toolbar>
        
        <main>
            
            ${this.model?.type=='cam'?this.renderCams():''}

            <s-snotel .model=${this.model}></s-snotel>
            
            <b-hr short thick pad="md" ?hidden=${!this.model||this.model.type=='weather'}></b-hr>

            <c-weather-forecast 
                .autoLoad=${this.model?.type!='cam'}
                lng=${this.model?.longitude} 
                lat=${this.model?.latitude}
                @loaded=${this.onWeatherLoaded}
            ></c-weather-forecast>

        </main>
    `}

    renderCams(){

        let url = `/web-cam/`+this.model._id

        if( this.model.url  )
            url = this.model.url// +'?'+(new Date().getTime())
        
        if( this.model.cameraId ){
            let file = encodeURIComponent(this.model.filename)
            url = `https://tripcheck.com/RoadCams/cams/${file}?rand=${new Date().getTime()}`
        }
            

        return html`
            <b-grid cols=1 class="cams">
                <img src=${url}>
            </b-grid>
        `        

    }

    viewHourlyWeather(){
        goTo(`weather/hourly/${this.model.longitude},${this.model.latitude}`)
    }

})

export default customElements.get('s-detail')