import { LitElement, html, css } from 'lit'
import 'bui/elements/embed'
import Previewer from 'bui/presenters/previewer'
import {Dialog, Menu} from 'bui'
import device from 'bui/util/device'

customElements.define('m-reports-row', class extends LitElement{

    static properties = {
        noshadow: {type: Boolean},
        fullsize: {type: Boolean}
    }

    static listeners = {
        model: {
            'change': 'requestUpdate',
            'destroy': 'remove'
        }
    }

    static styles = [, css`

        b-paper {
            border-radius: 16px;
            border: solid 1px var(--theme-bgd-accent);
            overflow: hidden;
        }

        /*.header {
            position: sticky;
            top: var(--gutter-negative);
            background-color: var(--theme-bgd);
            z-index: 10;
            border-radius: 16px 16px 0 0;
        }*/

        .header, .footer {
            padding: 1em;
        }

        .footer {
            border-radius: 0 0 16px 16px;
        }

        img {
            width: 100%;
            object-fit: cover;
        }

        m-report-img, b-embed {
            /*margin: 0 .125em;*/
        }

        :host([noshadow]) b-paper {
            box-shadow: none;
            border: none;
        }

    `]

    // static header(){ return html`
    //     <div w="1fr">Title</div>
    //     <div w="1fr">Published</div>
    // `}

    render(){return html`
        <b-paper overshadow compact>

            <b-grid cols=1 gap=".25">
            
            <b-flex class="header" top>
                <b-grid cols=1 gap=" ">
                    <b-text sm ucase bold color=${this.model.get('published')?'theme':'muted'}>
                        <b-ts .date=${this.model.get('date')||this.model.get('published')||this.model.get('ts_created')} format="MMM D, YYYY"></b-ts>

                        <b-text ?hidden=${this.get('published')}> - Unpublished</b-text>
                        
                    </b-text>
                    <b-text ?dim=${!this.get('title')} xbold lg>
                        <b-text link @click=${this.edit}>
                            ${this.get('title')||'No Title'}
                        </b-text>
                    </b-text>
                    <b-text ?hidden=${!this.get('body')} body dim>
                        <b-text .html=${this.get('body')}></b-text>
                    </b-text>
                </b-grid>

                <b-btn clear noshrink pill lg icon="more_vert" @click=${this.showMenu} ?hidden=${!this.model.isMine}></b-btn>

            </b-flex>


            ${this.get('video')?html`
                <b-embed url=${this.get('video')}></b-embed>
            `:''}

            ${this.get('files').map((m,i)=>html`
                <m-report-img .model=${m} ?fullsize=${this.fullsize}></m-report-img>
            `)}

            <b-grid cols=3 gap=".25" ?hidden=${!this.get('google_photos')}>
                <m-report-google-photos .model=${this.model} grid limit=${this.fullsize?0:3}></m-report-google-photos>
            </b-grid>

            <b-flex class="footer">
                <b-text block sm muted>
                    <!--Published <b-ts .date=${this.get('published')} fallback="not yet"></b-ts>-->
                </b-text>

                <b-text>
                    ${this.model.make} ${this.model.model}
                </b-text>
            </b-flex>

            </b-grid>

        </b-paper>
    `}

    showMenu(e){
        new Menu([
            {label: 'Edit', fn: 'edit'},
            {label: 'Delete', fn: 'destroy'},
            '-',
            {label: 'Public View', fn: 'view'},
        ], {handler: [this,e]}).popOver(e.currentTarget)
    }

    edit(){
        this.emitEvent('edit', this)
    }
    
    view(){
        this.emitEvent('view', this)
    }

    async destroy(e){
        if( await Dialog.confirmDelete().popOver(e) ){
            this.model.destroySync()
            this.remove()
        }
    }

})

export default customElements.get('m-reports-row')


// import { LitElement, html, css } from 'lit'

customElements.define('m-report-img', class extends LitElement{

    static properties = {
        fullsize: {type: Boolean}
    }

    static listeners = {
        model: {'change': 'requestUpdate'}
    }

    static styles = css`
        :host {
            display: block;
            position:relative;
            line-height: 0;
        }

        .preview {
            position: absolute;
        }

        

        .gps:not(:hover) {
            opacity: .7;
        }

        img {
            position: relative;
            width: 100%;
            object-fit: cover;
            aspect-ratio: var(--aspect-ratio, 1/1);
        }

        img:first-child {
            background-color: var(--theme-bgd-accent);
            background-color: rgb(var(--palette-muted));
            background: linear-gradient(to top,
                rgb(var(--palette-lightmuted)),
                rgb(var(--palette-muted)));
            /*background: linear-gradient(to top,
                rgb(var(--palette-vibrant)),
                rgb(var(--palette-lightvibrant)),
                rgb(var(--palette-lightmuted)),
                rgb(var(--palette-muted)));*/
        }

        .footer {
            position: absolute;
            bottom: .5em;
            right: .5em;
            left: .5em;
        }

        .caption {
            background: rgba(var(--theme-bgd-rgb), .6);
            padding: 0.25em 0.5em;
            border-radius: 3em;
        }
    `

    clickMenu(){
        this.preview()
    }

    preview(){
        Previewer.open(this.model)
    }

    viewMapLocation(e){
        e.preventDefault();
        e.stopPropagation()

        if( this.model.hasGPS){
            let [lng, lat] = this.model.lngLat()
            // goTo(`map/${lng},${lat},12`)
            let zoom = device.isSmall ? 9.25 : 11.25;
            goTo('map', {flyTo: [...this.model.lngLat(), zoom]})
        }
            
    }

    updated(){
        let {width, height} = this.model.get('traits')

        this.model.palette?.applyVars(this)
        this.style.setProperty('--aspect-ratio', width/height)
    }

    render(){return html`
        <img src=${this.model.previewURL} loading="lazy" class="${device.isSmall&&!this.fullsize?'':'preview'}">

        ${device.isSmall&&!this.fullsize?'':html`
        <img src=${this.model.displayURL} loading="lazy">
        `}

        

        <b-flex class="footer">

            <div>
            <b-btn color="white" lg pill icon="location_on" class="gps" @click=${this.viewMapLocation} ?hidden=${!this.model.hasGPS||!this.model.shareGPS}
                _tooltip="View appox location on the map"
            ></b-btn>
            </div>

            <b-text class="caption" sm semibold ?hidden=${!this.model.get('description')}>
                ${this.model.get('description')}
            </b-text>
        </b-flex>
    `}

})

// export default customElements.get('m-report-img')