import { LitElement, html, css } from 'lit'
import Chart, {Plugins, Ticks} from './chart'
import dayjs from 'dayjs'

customElements.define('s-chart-wind', class extends Chart{

    get options(){return {
        scales: {
            x: {
                ticks: {
                    callback: Ticks.byHour,
                    font: {size: 14},
                    padding: 20
                },
            },
            y: {
                suggestedMin: 10,
            }
        }
    }}

    get plugins(){ return [
        Plugins.addPointLabels({
            suffix:'',
            format: this.formatPointLabel.bind(this)
        })
    ]}

    formatPointLabel(value, index, datasetIndex, {ctx, x, y}){
        if( datasetIndex != 1 ) return value

        let windDir = this.data['direction:wind'][index]

        if( !this.windEls )
            this.windEls = []

        let el = this.windEls[index]
        if( !el ){
            el = this.windEls[index] = document.createElement('c-chart-wind-arrow')
            this.$$('.canvas-wrap').appendChild(el)
        }

        el.style.top = y+'px'
        el.style.left = x+'px'
        el.direction = windDir

        return value
    }

    get chartData(){
        return {
            labels: this.data.time.map(t=>dayjs(t)),
            datasets: [{
                label: 'Gusts (mph)',
                data: this.data['wind-speed:gust'],
                borderColor: '#C2185B'
            },{
                label: 'Surface Wind (mph)',
                data: this.data['wind-speed:sustained'],
                borderColor: '#7C4DFF'
            }]
        }
    }

})
              

customElements.define('c-chart-wind-arrow', class extends LitElement{

    static get styles(){return css`
        :host {
            display: inline-block;
            position: absolute;
            height: 1em;
            width: 1em;
            margin-left: .75em;
            margin-top: -.65em;
        }

        b-icon {
            transform: rotate(var(--dir))
        }
    `}

    set direction(val){
        this.style.setProperty('--dir', (val+180)+'deg')
        this.title = `Wind direction: ${val} deg`
    }

    render(){return html`
        <b-icon name="up"></b-icon>
    `}

})
