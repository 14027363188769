import { LitElement, html, css } from 'lit'
import RoutedView from 'bui/app/views/routed'
import scrollbars from 'bui/helpers/scrollbars'

export {html, css}

export default class extends RoutedView{

    static get styles(){return css`
        :host {
            display: block;
            position:relative;
            display: grid;
            grid-template-rows: auto 1fr;
            max-height: calc(96vh + var(--safe-top));
        }

        main {
            overflow: auto;
            padding: var(--gutter-safe);
            padding-top: 0;
        }

        ${scrollbars.hide('main')}

        [chart] {
            height: 300px;
            width: 3000px;
        }

        [chart] + [chart] {
            margin-top: 2em;
        }
    `}

    async load(id, attrs, state){
        let {lng, lat} = state.params
        this.refreshData(lng, lat)
    }

    async refreshData(lng, lat){

        this.data = null
        this.update()

        if( !lng || !lat ) return this.close()

        this.data = await fetch(`/api/weather/hourly/${lng},${lat}`).then(r=>r.json())

        if( this.data ){
            this.panel.title = 'Hourly Forecast - '+this.data['location-area']
        }

        this.update()

        return this.data
    }

    render(){return html`

        <b-panel-toolbar></b-panel-toolbar>

        <main>

            ${this.renderCharts()}

        </main>
    `}

    renderCharts(){ return ''}

    scrollToEnd(){
        this.main.scrollLeft = this.main.scrollWidth
    }

    get main(){ return this.$$('main', true)}

}